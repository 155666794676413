import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import linkedin from "images/linkedin.svg";
// import allRevenueSource from "images/nav-why-ay/all-revenue-source.svg";
import rightArrowIconWhyAy from "images/nav-why-ay/arrow-right.svg";
import rightArrow from "images/right-arrow.svg";
import rightArrowGreen from "images/right-arrow-green.svg";
// import britain from "images/nav-why-ay/britain-flag.svg";
// import canada from "images/nav-why-ay/canada-flag.svg";
// import dashboard from "images/nav-why-ay/dashboard.svg";
// import france from "images/nav-why-ay/france-flag.svg";
// import industryInsights from "images/nav-why-ay/industry-insights.svg";
// import monitoring from "images/nav-why-ay/monitoring.svg";
// import netherlands from "images/nav-why-ay/netherlands-flag.svg";
// import romania from "images/nav-why-ay/romania-flag.svg";
// import sliceAndDiceData from "images/nav-why-ay/slice-and-dice-data.svg";
// import spain from "images/nav-why-ay/spain-flag.svg";
// import usa from "images/nav-why-ay/usa-flag.svg";
import youtube from "images/youtube.svg";
import badgeNew from "img/nav/badge-new.svg";
import rightArrowNavbar from "img/nav/navbar-arrow-right.svg";
import trafficShapingIcon from "img/nav/traffic-shaping-icon.svg";
import yieldImg1 from "img/nav/yeild-img-1.svg";
import yieldImg2 from "img/nav/yeild-img-2.svg";
import yieldImg3 from "img/nav/yeild-img-3.svg";
import { useScrollPosition } from "./useScrollPosition/useScrollPosition";
import { AnimatedLogo } from "./AnimatedLogo";
import { BOOK_CALL_LINK, SIGNUP_LINK } from "./constants";
import CustomButton from "./CustomButton";
import TransparentGradientButton from "./TransparentGradientButton";
import * as styles from "./navbar.module.scss";

const getShowBgOfScroll = (scrollY: number) => scrollY > 0;
function TopNavbar() {
    // SSR & First load: always show
    const data = useStaticQuery(graphql`
        query navigationBannerQuery {
            navigationBanner {
                description
                link
                linkText
                countdown
            }
        }
    `);

    const { description, link, linkText, countdown } = data.navigationBanner;
    const [showBg, setShowBg] = useState(false);
    const [dropdownExpanded, setDropdownExpanded] = useState<string | null>(null);
    const [toggleNav, setToggleNav] = useState(false);
    const mobileMenuRef = useRef(null);

    const addLeadingZero = (value: number) => {
        return value < 10 ? `0${value}` : String(value);
    };

    const separateDigits = (value: number) => {
        const stringValue = addLeadingZero(value);
        return {
            left: stringValue.charAt(0),
            right: stringValue.charAt(1),
        };
    };

    const countdownTo = () => {
        const date = new Date(countdown);
        const now = new Date();
        const diff = date.getTime() - now.getTime();
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        const separatedDays = separateDigits(days);
        const separatedHours = separateDigits(hours);
        const separatedMinutes = separateDigits(minutes);
        const separatedSeconds = separateDigits(seconds);

        return {
            days: {
                left: separatedDays.left,
                right: separatedDays.right,
            },
            hours: {
                left: separatedHours.left,
                right: separatedHours.right,
            },
            minutes: {
                left: separatedMinutes.left,
                right: separatedMinutes.right,
            },
            seconds: {
                left: separatedSeconds.left,
                right: separatedSeconds.right,
            },
        };
    };

    const [countDownTime, setCountDownTime] = useState(countdownTo());

    useEffect(() => {
        if (!countdown) return;

        const interval = setInterval(() => {
            setCountDownTime(countdownTo());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const closeOpenMenus = useCallback(
        (e: Event) => {
            if (mobileMenuRef.current && toggleNav && !(mobileMenuRef.current as any).contains(e.target)) {
                setToggleNav(false);
            }
        },
        [toggleNav]
    );

    useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);

        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
        };
    }, [closeOpenMenus]);

    useScrollPosition(({ currPos }: { currPos: { y: number } }) => {
        const y = -currPos.y;
        setShowBg(getShowBgOfScroll(y));
    });

    const CloseIcon = () => {
        return (
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="8" fill="url(#paint0_linear_5106_124913)" />
                <path
                    d="M26 14L14 26M14 14L26 26"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_5106_124913"
                        x1="-1.22115e-08"
                        y1="22.8261"
                        x2="40.0095"
                        y2="22.6851"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#6800FF" stop-opacity="0.18" />
                        <stop offset="0.828304" stop-color="#6800FF" stop-opacity="0.18" />
                    </linearGradient>
                </defs>
            </svg>
        );
    };

    const SvgHamburger = () => (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 20H29M11 14H29M11 26H29"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );

    const caretClassName = () => {
        return [styles.light_caret, styles.caret_common].join(" ");
    };
    const TopNavbarTitle: FC<{ title: string; caret: boolean; id: string }> = ({ title, caret, id }) => {
        return (
            <div
                className={[
                    caret ? caretClassName() : "",
                    styles.light_color,
                    styles.dropdown,
                    dropdownExpanded === id ? styles.activeDropdown : "",
                ].join(" ")}
            >
                {title}
            </div>
        );
    };

    useLayoutEffect(() => {
        const y = window.scrollY;
        setShowBg(getShowBgOfScroll(y));
    }, []);

    const onLinkClick = () => {
        setTimeout(() => {
            setDropdownExpanded(null);
            setToggleNav(false);
        }, 200);
    };

    const overviewData = [
        {
            title: "Customer Stories",
            description: "See how our customers are empowered by AY",
            link: "/success-stories",
        },
        {
            title: "Apps and Integration",
            description: "Connect AY platform with your own systems",
            link: "/apps-and-integrations",
        },
        {
            title: "About Us",
            description: "Learn about our story, values and purpose",
            link: "/about-us",
        },
    ];

    const renderOverviewItems = () =>
        overviewData.map(({ title, description, link }, index) => {
            return (
                <Link className={`${styles.overviewLink} mt-1 mb-1`} to={link} onClick={onLinkClick} key={index}>
                    <div className={`col ${styles.overviewItem}`}>
                        <div className={styles.overviewContainer}>
                            <h5 className={styles.overviewTitle}>{title}</h5>
                            <div className={styles.arrowWrapper}>
                                <img src={rightArrow} alt="" />
                            </div>
                        </div>
                        <p className={styles.description}>{description}</p>
                    </div>
                </Link>
            );
        });

    // const featuresData = [
    //     {
    //         title: "All Revenue, All in Real-Time",
    //         description: "Accurate 1st party data in Real-Time across all your revenue sources",
    //         link: "/all-revenue-source",
    //         img: allRevenueSource,
    //     },
    //     {
    //         title: "Slice & Dice the Data Cube",
    //         description: "Most granular data you have ever seen with multi-metrics, dimensions, filters & more",
    //         link: "/slice-and-dice",
    //         img: sliceAndDiceData,
    //     },
    //     {
    //         title: "Not Just Dashboards",
    //         description: "Actionable insights, visual analysis, interactive reporting",
    //         link: "/dashboards",
    //         img: dashboard,
    //     },
    //     {
    //         title: "Industry Insights",
    //         description: "Granular benchmark to lifetime value, revenue/user and user segments",
    //         link: "/industry-insights",
    //         img: industryInsights,
    //     },
    //     {
    //         title: "Real-Time Monitoring Alerts",
    //         description: "Catch issues before they impact revenue, alert your team in",
    //         link: "/real-time-alerts",
    //         img: monitoring,
    //     },
    // ];

    const mobileItems = [
        {
            // mainTitle: "OVERVIEW",
            items: [
                {
                    title: "Customer Stories",
                    link: "/success-stories",
                },
                // {
                //     title: "AY Customer Support",
                //     link: "/help-center",
                // },
                {
                    title: "Apps and Integration",
                    link: "/apps-and-integrations",
                },
                {
                    title: "About Us",
                    link: "/about-us",
                },
            ],
        },
    ];

    // const renderFeaturesItems = () =>
    //     featuresData.map(({ title, description, link, img }, index) => {
    //         return (
    //             <div className={`col-6 ay-nav-tabs hover ${styles.navItemWhyAy}`} key={index}>
    //                 <Link onClick={onLinkClick} className="p-0 m-1 link" to={link}>
    //                     <div className={styles.featuresList}>
    //                         <img className={styles.featureImage} src={img} alt="" />
    //                         <div className={styles.featureItemsWrapper}>
    //                             <h5 className={styles.featureTitle}>{title}</h5>
    //                             <p className={styles.featureDescription}>{description}</p>
    //                         </div>
    //                         <div className={styles.featureArrow}>
    //                             <img src={rightArrow} alt="" />
    //                         </div>
    //                     </div>
    //                 </Link>
    //             </div>
    //         );
    //     });

    const renderFeaturesItemsMobile = () =>
        mobileItems.map(({ items }, index) => {
            return (
                <div key={index}>
                    {/* <h6 className={styles.mobileNavMainTitleWhyAy}>{mainTitle}</h6> */}
                    <ul className="list-unstyled mb-0">
                        {items.map(({ title, link }, index) => {
                            return (
                                <li key={index}>
                                    <Link className={styles.mobileNavItemWhyAy} onClick={onLinkClick} to={link}>
                                        {title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
    };

    useEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            sticky="top"
            style={{
                background: undefined,
            }}
            className={[
                showBg || dropdownExpanded != null ? "navbar-expand-lg-bg" : "",
                dropdownExpanded != null ? "navbar-expand-lg-no-transition" : "",
                styles.navbar,
            ].join(" ")}
            expanded={toggleNav}
            onMouseLeave={() => setDropdownExpanded(null)}
        >
            <div className={styles.banner}>
                <p className={styles.bannerText}>
                    {countdown ? (
                        <div className={styles.bannerCountdown}>
                            <span className={styles.itemWrapper}>
                                <span className={styles.item}>
                                    <span className={styles.number}>{countDownTime.days.left}</span>
                                    <span className={styles.number}>{countDownTime.days.right}</span>
                                    <span>:</span>
                                </span>
                                <span className={styles.unit}>Days</span>
                            </span>

                            <span className={styles.itemWrapper}>
                                <span className={styles.item}>
                                    <span className={styles.number}>{countDownTime.hours.left}</span>
                                    <span className={styles.number}>{countDownTime.hours.right}</span>
                                    <span>:</span>
                                </span>
                                <span className={styles.unit}>Hours</span>
                            </span>

                            <span className={styles.itemWrapper}>
                                <span className={styles.item}>
                                    <span className={styles.number}>{countDownTime.minutes.left}</span>
                                    <span className={styles.number}>{countDownTime.minutes.right}</span>
                                    <span>:</span>
                                </span>
                                <span className={styles.unit}>Minutes</span>
                            </span>

                            <span className={styles.itemWrapper}>
                                <span className={styles.item}>
                                    <span className={styles.number}>{countDownTime.seconds.left}</span>
                                    <span className={styles.number}>{countDownTime.seconds.right}</span>
                                </span>
                                <span className={styles.unit}>Seconds</span>
                            </span>
                        </div>
                    ) : null}
                    <span>
                        {description}
                        <a href={link} className={styles.mobileLink}>
                            {linkText}
                        </a>
                    </span>
                </p>
                <Link to={link} className={styles.bannerLinkWrapper}>
                    <p className={styles.bannerLinkText}>{linkText}</p>
                    <img src={rightArrowNavbar} alt="" />
                </Link>
            </div>
            <Container ref={mobileMenuRef} className={styles.containerWrapper}>
                <Navbar.Brand
                    href="/"
                    onClick={ev => {
                        ev.preventDefault();
                        navigate("/");
                    }}
                    aria-label="Assertive Yield Logo"
                >
                    <AnimatedLogo type="light" />
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setToggleNav(nav => !nav)} aria-controls="responsive-navbar-nav">
                    {toggleNav ? <CloseIcon /> : <SvgHamburger />}
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav" className="nav-mega">
                    <div className={`seperator-menu d-block d-lg-none ${styles.mobileTopSeparator}`} />
                    <Nav className={`m-auto ${styles.mobileWrapperScroll}`}>
                        <Link to="/" className={styles.mobileHomeLink}>
                            Home
                        </Link>
                        <NavDropdown
                            title={<TopNavbarTitle title="Why AY" id="why-ay" caret={true} />}
                            id="collasible-nav-dropdown"
                            show={dropdownExpanded === "why-ay"}
                            onMouseEnter={() => (!isMobile ? setDropdownExpanded("why-ay") : null)}
                            onClick={() =>
                                isMobile && dropdownExpanded === "why-ay"
                                    ? setDropdownExpanded(null)
                                    : setDropdownExpanded("why-ay")
                            }
                        >
                            <div
                                className="section"
                                // onMouseLeave={() => setDropdownExpanded(null)}
                            >
                                <div className="container ">
                                    <div className={`row ${styles.wrapperScroll}`}>
                                        <div className="col-12 pe-2 d-none d-lg-block">
                                            <div className="row flex-column">
                                                {/* <div className="col mb-2">
                                                    <h4>OVERVIEW</h4>
                                                </div> */}
                                                <div className={styles.overviewWrapper}>{renderOverviewItems()}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-8 ps-3">
                                            <div className="row d-none d-lg-flex mb-4">
                                                <div className="col-12 mb-2 d-none d-lg-block">
                                                    <h4>FEATURES</h4>
                                                </div>
                                                {renderFeaturesItems()}
                                            </div>
                                        </div> */}
                                        <div
                                            className={`d-md-flex d-lg-none flex-column ${styles.featuresItemsMobileWrapper}`}
                                        >
                                            {renderFeaturesItemsMobile()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavDropdown>
                        <NavDropdown
                            title={<TopNavbarTitle title="Products" id="products" caret={true} />}
                            id="collasible-nav-dropdown"
                            show={dropdownExpanded === "products"}
                            onMouseEnter={() => (!isMobile ? setDropdownExpanded("products") : null)}
                            onClick={() =>
                                isMobile && dropdownExpanded === "products"
                                    ? setDropdownExpanded(null)
                                    : setDropdownExpanded("products")
                            }
                        >
                            <div
                                className="section"
                                //  onMouseLeave={() => setDropdownExpanded(null)}
                            >
                                <div className={`container ${styles.wrapperScroll}`}>
                                    <div className="row nav-block- justify-content-center">
                                        <div
                                            className={`col-3 d-none d-lg-block ${styles.productSection}`}
                                            style={{
                                                paddingRight: "45px",
                                            }}
                                        >
                                            <h4>MANAGE</h4>
                                        </div>
                                        <div
                                            className={`col-3 d-none d-lg-block ps-0 ${styles.productSection}`}
                                            style={{
                                                paddingRight: "45px",
                                            }}
                                        >
                                            <h4>ANALYZE</h4>
                                        </div>
                                        <div
                                            className={`col-6 d-none d-lg-block ps-0 ${styles.productSection}`}
                                            style={{
                                                paddingRight: "45px",
                                            }}
                                        >
                                            <h4>OPTIMIZE WITH AI</h4>
                                        </div>
                                        <div className={`row product gx-1 mb-lg-4 ${styles.productsMobileWrapper}`}>
                                            <div className="col d-block d-lg-none">
                                                <Link
                                                    className={`${styles.productItemLink} mb-0 mb-lg-2`}
                                                    onClick={onLinkClick}
                                                    to="/ay_prebidserver"
                                                >
                                                    <ul
                                                        className={`list-group list-unstyled h-100 ${styles.productItem}`}
                                                    >
                                                        <li className="d-none d-lg-block">
                                                            <p className={styles.categorySupTitle}>Optimize with AI</p>
                                                        </li>
                                                        <li>
                                                            <div className="all-head">
                                                                <div>
                                                                    <img src={yieldImg3} alt="" />
                                                                </div>
                                                                <h5 className={styles.ayPrebidTitle}>
                                                                    AY Prebid Server
                                                                </h5>
                                                                <span className={styles.newBadge}>
                                                                    <img src={badgeNew} alt="" />
                                                                </span>
                                                            </div>
                                                        </li>
                                                        <li className="d-none d-lg-block">
                                                            <p>QPS Optimization - Green Traffic!</p>
                                                        </li>
                                                        <li className="d-none d-lg-block">
                                                            <p>Real-time User Profile Prediction</p>
                                                        </li>
                                                        <li className="d-none d-lg-block learn-more">
                                                            <span className={styles.learnMore}>Learn More</span>
                                                            <img src={rightArrowIconWhyAy} />
                                                        </li>
                                                    </ul>
                                                </Link>
                                            </div>
                                            <div className="col ">
                                                <Link
                                                    className={`${styles.productItemLink} d-none d-lg-block`}
                                                    to="/ay_prebidserver"
                                                    onClick={onLinkClick}
                                                >
                                                    <div className={`${styles.productItem} ${styles.ayPrebid}`}>
                                                        <div className={styles.header}>
                                                            <span className={styles.icon}>
                                                                <img src={yieldImg3} alt="" />
                                                            </span>
                                                            <p className={`${styles.title} ${styles.productTitle}`}>
                                                                AY Prebid Server
                                                            </p>
                                                            <span>
                                                                <img src={badgeNew} alt="" />
                                                            </span>
                                                        </div>
                                                        <div className={styles.benefits}>
                                                            <Link
                                                                className={`${styles.benefit} ${styles.benefitLink}`}
                                                                to="/traffic-shaping-for-publishers"
                                                            >
                                                                <span>AI Traffic Shaping for Publishers</span>
                                                                <div className={styles.benefitArrow}>
                                                                    <img src={rightArrow} alt="" />
                                                                </div>
                                                                <div className={styles.benefitGreenArrow}>
                                                                    <img src={rightArrowGreen} alt="" />
                                                                </div>
                                                            </Link>
                                                            <p className={styles.benefit}>AI Dynamic Flooring</p>
                                                            <p className={styles.benefit}>Identity Solutions</p>
                                                            <p className={`${styles.benefit} border-0 pb-0 mb-0`}>
                                                                Advanced Yield Optimization
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                                <Link
                                                    className={styles.productItemLink}
                                                    onClick={onLinkClick}
                                                    to="/yield-manager"
                                                >
                                                    <ul
                                                        className={`list-group list-unstyled h-100 ${styles.productItem}`}
                                                    >
                                                        <li>
                                                            <div className="all-head mb-0 mb-lg-3">
                                                                <div>
                                                                    {" "}
                                                                    <img src={yieldImg3} alt="" />
                                                                </div>
                                                                <h5 className={styles.productTitle}>Yield Manager</h5>
                                                            </div>
                                                        </li>
                                                        <p className={styles.benefit}>HB Wrapper</p>
                                                        <p className={styles.benefit}>Tag Manager</p>
                                                        <p className={styles.benefit}>A/B/n Testing</p>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink} border-0 pb-0 mb-0`}
                                                            to="/yield-intelligence"
                                                        >
                                                            <span>Yield Intelligence</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                    </ul>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link
                                                    className={styles.productItemLink}
                                                    onClick={onLinkClick}
                                                    to="/yield-intelligence"
                                                >
                                                    <ul
                                                        className={`list-group list-unstyled h-100 ${styles.productItem}`}
                                                    >
                                                        <li>
                                                            <div className="all-head mb-0 mb-lg-3">
                                                                <div>
                                                                    <img src={yieldImg1} alt="" />
                                                                </div>
                                                                <h5 className={styles.productTitle}>
                                                                    Yield Intelligence
                                                                </h5>
                                                            </div>
                                                        </li>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink}`}
                                                            to="/all-revenue-source"
                                                        >
                                                            <span>Web/Yield/Prebid Analytics</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink}`}
                                                            to="/industry-insights"
                                                        >
                                                            <span>Industry Benchmark</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink}`}
                                                            to="/dashboards"
                                                        >
                                                            <span>Advanced Dashboards</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink}`}
                                                            to="/real-time-alerts"
                                                        >
                                                            <span>Real-Time Alerts</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink}`}
                                                            to="/all-revenue-source"
                                                        >
                                                            <span>Financial / SSP Report</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink}`}
                                                            to="/slice-and-dice"
                                                        >
                                                            <span>Granular Data in Real-time</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                        <li className={`d-none d-lg-block ${styles.benefit}`}>
                                                            <p className="mb-0">Panorama 360°</p>
                                                        </li>
                                                        <li
                                                            className={`d-none d-lg-block ${styles.benefit} border-0 pb-0 mb-0`}
                                                        >
                                                            <p className="mb-0">Traffic Acquisition</p>
                                                        </li>
                                                    </ul>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link
                                                    className={`${styles.productItemLink} mb-1`}
                                                    onClick={onLinkClick}
                                                    to="/yield-optimizer"
                                                >
                                                    <ul
                                                        className={`list-group list-unstyled h-100 ${styles.productItem}`}
                                                    >
                                                        <li>
                                                            <div className="all-head mb-0 mb-lg-3">
                                                                <div>
                                                                    <img src={yieldImg2} alt="" />
                                                                </div>
                                                                <h5 className={styles.productTitle}>Yield Optimizer</h5>
                                                            </div>
                                                        </li>
                                                        <Link
                                                            className={`${styles.benefit} ${styles.benefitLink}`}
                                                            to="/traffic-shaping-for-publishers"
                                                        >
                                                            <span>AI Traffic Shaping for Publishers</span>
                                                            <div className={styles.benefitArrow}>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                            <div className={styles.benefitGreenArrow}>
                                                                <img src={rightArrowGreen} alt="" />
                                                            </div>
                                                        </Link>
                                                        <li className={`d-none d-lg-block ${styles.benefit}`}>
                                                            <p className="mb-0">AI Dynamic Flooring</p>
                                                        </li>
                                                        <li className={`d-none d-lg-block ${styles.benefit}`}>
                                                            <p className="mb-0">AI Layouts</p>
                                                        </li>
                                                        <li className={`d-none d-lg-block ${styles.benefit}`}>
                                                            <p className="mb-0">AI Revenue Attribution</p>
                                                        </li>
                                                        <li className={`d-none d-lg-block ${styles.benefit}`}>
                                                            <p className="mb-0">AI WebAds Mediation</p>
                                                        </li>
                                                        <li className={`d-none d-lg-block ${styles.benefit}`}>
                                                            <p className="mb-0">AI Revenue Predictions</p>
                                                        </li>
                                                    </ul>
                                                </Link>
                                            </div>
                                            <div className="col">
                                                <Link
                                                    className={`${styles.productItemLink} mb-1`}
                                                    onClick={onLinkClick}
                                                    to="/traffic-shaping"
                                                >
                                                    <ul
                                                        className={`list-group list-unstyled h-100 ${styles.productItem}`}
                                                    >
                                                        <li>
                                                            <div className="all-head mb-0 mb-lg-3">
                                                                <div>
                                                                    <img src={trafficShapingIcon} alt="" />
                                                                </div>
                                                                <h5 className={styles.productTitle}>
                                                                    SSP Traffic Shaping
                                                                </h5>
                                                            </div>
                                                        </li>
                                                        <li className={`d-none d-lg-block ${styles.benefit}`}>
                                                            <p className="mb-0">QPS Optimization</p>
                                                        </li>
                                                        <li
                                                            className={`d-none d-lg-block ${styles.benefit} border-0 pb-0 mb-0`}
                                                        >
                                                            <p className="mb-0">User Profile Prediction</p>
                                                        </li>
                                                    </ul>
                                                </Link>
                                            </div>

                                            {/* <div className="col ">
                        <ul className="list-group list-unstyled h-100">
                          <Link
                            onClick={onLinkClick}
                            to="/traffic-acquisition"
                            className="flex-link"
                          >
                            <li>
                              <div className="all-head">
                                <div>
                                  <img src={yieldImg1} alt="" />
                                </div>
                                <h5>Traffic Acquisition</h5>
                              </div>
                            </li>
                            <li className="d-none d-lg-block">
                              <p className="yeild-para">
                                Revenue spend data comes together
                              </p>
                            </li>
                            <li className="d-none d-lg-block">
                              <p>Traffic acquisition</p>
                            </li>

                            <li className="d-none d-lg-block learn-more">
                              Learn More
                              <i className="fa-solid fa-arrow-right" />
                            </li>
                          </Link>
                        </ul>
                      </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavDropdown>
                        {/* <NavDropdown
              title={<TopNavbarTitle bg={bg} title="Solutions" caret={true} />}
              id="collasible-nav-dropdown"
            >
              <div className="section">
                <div className="container ">
                  <div className="row">
                    <div className="col-10 mb-100">
                      <ul className="list-group list-unstyled ">
                        <li>
                          <h4>OUR SOLUTION</h4>
                        </li>
                      </ul>
                      <div className="d-flex justify-content-center mt-5">
                        <ul className="list-group list-unstyled ">
                          <li className="mb-4 ">
                            <div className="all-head">
                              <img src={company} alt="" className="mb-2" />
                              <h5>By Company Type</h5>
                            </div>
                            <p className="opacity-50">
                              Several types of companies in sell-side benefits
                              from our solutions
                            </p>
                          </li>
                          <li>
                            <h5>Publishers</h5>
                          </li>
                          <li>
                            <h5>Supply Side Platforms (SSPs)</h5>
                          </li>
                          <li className="mt-4">
                            <a href="">Learn More</a>
                            <i className="fa-solid fa-arrow-right" />
                          </li>
                        </ul>
                        <ul
                          className="list-group list-unstyled "
                          style={{ marginLeft: "150px" }}
                        >
                          <li className="mb-4">
                            <div className="all-head">
                              <img src={user} alt="" className="mb-2 " />
                              <h5>By Team</h5>
                            </div>
                            <p className="opacity-50">
                              Several types of companies in sell-side benefits
                              from our solutions
                            </p>
                          </li>
                          <li>
                            <h5>AdOps/Programmatic</h5>
                          </li>
                          <li>
                            <h5>Audience Development</h5>
                          </li>
                          <li className="mt-4">
                            <a href="">Learn More</a>
                            <i className="fa-solid fa-arrow-right" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </NavDropdown> */}
                        <NavDropdown
                            title={<TopNavbarTitle title="Resources" id="resources" caret={true} />}
                            id="collasible-nav-dropdown"
                            show={dropdownExpanded === "resources"}
                            onMouseEnter={() => (!isMobile ? setDropdownExpanded("resources") : null)}
                            onClick={() =>
                                isMobile && dropdownExpanded === "resources"
                                    ? setDropdownExpanded(null)
                                    : setDropdownExpanded("resources")
                            }
                        >
                            <div
                                className="section"
                                // onMouseLeave={() => setDropdownExpanded(null)}
                            >
                                <div className={`container ${styles.wrapperScroll}`}>
                                    <div className="row">
                                        <div className="col-12 col-lg-8 nav-block-2">
                                            <h4 className="d-none d-lg-block">ALL RESOURCES</h4>
                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-6 mb-0 mb-lg-3">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link
                                                            onClick={onLinkClick}
                                                            to="/blog"
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>Blog</h5>

                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    Free contents about the industry concepts, trends
                                                                    and opportunities
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6  mb-0 mb-lg-3">
                                                    <div className="ay-nav-tabs hover">
                                                        <AnchorLink
                                                            to="/blog#ebooks"
                                                            onAnchorLinkClick={() => onLinkClick()}
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>Reports, Guides and Videos</h5>
                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    Rich content about your main challenges to guide you
                                                                    and make your grow even more
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </AnchorLink>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6  mb-0 mb-lg-4">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link
                                                            onClick={onLinkClick}
                                                            to="/news/all/"
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>Press & Events</h5>
                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    See us on the news, at upcoming events and hear our
                                                                    podcasts
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6  mb-0 mb-lg-4">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link
                                                            onClick={onLinkClick}
                                                            to="/help-center/academy/"
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>AY Academy</h5>
                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    Self-paced learning through recorded demonstrations
                                                                    of our solutions, courses and tutorials.
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-block d-lg-none col-lg-6 mb-0 mb-lg-3">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link onClick={onLinkClick} to="/contact-us">
                                                            <h5>AY Contact</h5>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4  d-none d-lg-block">
                                            <div className="reach-us ps-md-1">
                                                <div className="row gx-0 m-0">
                                                    <div className="col-12 col-lg-8 d-none d-lg-block">
                                                        <h4>REACH US</h4>
                                                    </div>

                                                    <div className="col-12 col-lg-8">
                                                        <Link to="/contact-us" className="ay-contact">
                                                            <div>
                                                                <p className="font-weight-bold mb-0 title">
                                                                    AY Contact
                                                                </p>
                                                                <p className={`p14 d-none d-lg-block description mb-0`}>
                                                                    Get to know us and our tools
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 mb-2 d-none d-lg-block mt-4">
                                                        <p className="reach-icons">
                                                            Follow Us
                                                            <a
                                                                href="https://www.linkedin.com/company/assertive-yield/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <img src={linkedin} alt="linkedin logo" />
                                                            </a>
                                                            <a
                                                                href="https://youtube.com/@assertiveyield"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <img src={youtube} alt="youtube logo" />
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="col-8 ps-3 d-none d-lg-block">
                                                    <p className="mb-0 font-weight-bold">Partners</p>
                                                    <p className={`p14 ${styles.ayContact}`}>
                                                        Discover our amazing partners around the world or became a
                                                        partner
                                                    </p>
                                                    <div className={styles.flagsList}>
                                                        <img src={netherlands} alt="" />
                                                        <img src={usa} alt="" />
                                                        <img src={britain} alt="" />
                                                        <img src={france} alt="" />
                                                        <img src={spain} alt="" />
                                                        <img src={canada} alt="" />
                                                        <img src={romania} alt="" />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavDropdown>
                        <NavDropdown
                            title={<TopNavbarTitle title="Support" id="support" caret={true} />}
                            id="collasible-nav-dropdown"
                            show={dropdownExpanded === "support"}
                            onMouseEnter={() => (!isMobile ? setDropdownExpanded("support") : null)}
                            onClick={() =>
                                isMobile && dropdownExpanded === "support"
                                    ? setDropdownExpanded(null)
                                    : setDropdownExpanded("support")
                            }
                        >
                            <div className="section">
                                <div className={`container ${styles.wrapperScroll}`}>
                                    <div className="row">
                                        <div className="col-12 col-lg-8 nav-block-2">
                                            <h4 className="d-none d-lg-block">AY CUSTOMER SUPPORT</h4>
                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-6 mb-0 mb-lg-3">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link
                                                            onClick={onLinkClick}
                                                            to="/help-center/ay-support/"
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>Support & Services</h5>

                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    Experience faster and more effective assistance with
                                                                    our exclusive AY support
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6  mb-0 mb-lg-3">
                                                    <div className="ay-nav-tabs hover">
                                                        <AnchorLink
                                                            to="/product-updates/all/"
                                                            onAnchorLinkClick={() => onLinkClick()}
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>Product Updates</h5>
                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    What's new on AY? This collection is an overview of
                                                                    new products and features release in AY tools.
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </AnchorLink>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6  mb-0 mb-lg-4">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link
                                                            onClick={onLinkClick}
                                                            to="/apps-and-integrations"
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>Integrations</h5>
                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    Explore all you will find in our solutions, metrics,
                                                                    dimensions, integrations and more
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-6  mb-0 mb-lg-4">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link
                                                            onClick={onLinkClick}
                                                            to="/help-center/ay-glossary/"
                                                            className={styles.resourceLink}
                                                        >
                                                            <div>
                                                                <h5>AY Glossary</h5>
                                                                <p className="p14 mb-2 d-none d-lg-block">
                                                                    Browse an extensive list of industry-related
                                                                    buzzwords and find answers to your questions.
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-block d-lg-none col-lg-6 mb-0 mb-lg-3">
                                                    <div className="ay-nav-tabs hover">
                                                        <Link onClick={onLinkClick} to="/contact-us">
                                                            <h5>AY Contact</h5>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4  d-none d-lg-block">
                                            <div className="reach-us ps-md-1">
                                                <div className="row gx-0 m-0">
                                                    <div className="col-12 col-lg-8 d-none d-lg-block">
                                                        <h4>REACH US</h4>
                                                    </div>

                                                    <div className="col-12 col-lg-8">
                                                        <Link to="/contact-us" className="ay-contact">
                                                            <div>
                                                                <p className="font-weight-bold mb-0 title">
                                                                    AY Contact
                                                                </p>
                                                                <p className={`p14 d-none d-lg-block description mb-0`}>
                                                                    Get to know us and our tools
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <img src={rightArrow} alt="" />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 mb-2 d-none d-lg-block mt-4">
                                                        <p className="reach-icons">
                                                            Follow Us
                                                            <a
                                                                href="https://www.linkedin.com/company/assertive-yield/"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <img src={linkedin} alt="linkedin logo" />
                                                            </a>
                                                            <a
                                                                href="https://youtube.com/@assertiveyield"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <img src={youtube} alt="youtube logo" />
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="col-8 ps-3 d-none d-lg-block">
                                                    <p className="mb-0 font-weight-bold">Partners</p>
                                                    <p className={`p14 ${styles.ayContact}`}>
                                                        Discover our amazing partners around the world or became a
                                                        partner
                                                    </p>
                                                    <div className={styles.flagsList}>
                                                        <img src={netherlands} alt="" />
                                                        <img src={usa} alt="" />
                                                        <img src={britain} alt="" />
                                                        <img src={france} alt="" />
                                                        <img src={spain} alt="" />
                                                        <img src={canada} alt="" />
                                                        <img src={romania} alt="" />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavDropdown>
                        <Nav.Link
                            href="/pricing/"
                            onClick={ev => {
                                ev.preventDefault();
                                navigate("/pricing");
                            }}
                            className="dropdown-link-without-arrow"
                            style={{
                                color: "#fff",
                            }}
                        >
                            <TopNavbarTitle title="Pricing" caret={false} id="pricing" />
                        </Nav.Link>
                    </Nav>
                    <hr className="seperator-menu d-block d-lg-none mt-4 mb-4 d-lg-block d-none" />
                    <div className={`text-end ${styles.mobileButtonsWrapper} ${toggleNav ? "d-flex" : "d-none"}`}>
                        <div className="me-3 mb-3 mb-sm-0">
                            <TransparentGradientButton
                                text="Try for free"
                                to={SIGNUP_LINK}
                                bg="dark"
                                bgColor="#1f0746"
                            />
                        </div>
                        <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                            Let's Talk
                        </CustomButton>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNavbar;
