import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig } from "../types";

const RAW_METRICS = {
    wins_raw: {},
    bids_raw: {},
    bid_true_positive: {},
    bid_true_negative: {},
    bid_false_positive: {},
    bid_false_negative: {},
    win_true_positive: {},
    win_true_negative: {},
    win_false_positive: {},
    win_false_negative: {},
    missed_revenue: {},
    retained_revenue: {},
    requests_raw: {},
    retained_requests: {},
    win_revenue: {},
    bid_revenue: {},
    floor_sum: {},
    floor_raw_model_sum: {},
    feature_bid_sum_sum: {},
    feature_bid_cpm_cache_sum: {},
    feature_custom_num_1_sum: {},
    feature_custom_num_2_sum: {},
    feature_custom_num_3_sum: {},
    session_requests_sum: {},
    non_zero_count: {},
    session_bids_sum: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawRequestReductionServerSamplesMetrics = keyof typeof RAW_METRICS;

export const RequestReductionServerSamplesProfileMetricsIds = [
    "requests",
    "win_rate",
    "bid_rate",
    "wins",
    "bids",
    "win_revenue",
    "bid_revenue",
    "win_cpm",
    "bid_cpm",
    "request_rpmm",
    "floor_sum",
    "floor_sum_cpm",
    "floor_raw_model_sum",
    "floor_raw_model_sum_cpm",
    "feature_bid_sum_sum",
    "feature_bid_sum_avg",
    "feature_bid_cpm_cache_sum",
    "feature_bid_cpm_cache_avg",
    "feature_custom_num_1_sum",
    "feature_custom_num_1_avg",
    "feature_custom_num_2_sum",
    "feature_custom_num_2_avg",
    "feature_custom_num_3_sum",
    "feature_custom_num_3_avg",
    "session_requests_sum",
    "session_bids_sum",
    "session_bid_rate",
] as const;

export type RequestReductionServerSamplesProfileMetricsType =
    (typeof RequestReductionServerSamplesProfileMetricsIds)[number];

export const RequestReductionServerSamplesProfile = {
    bidder: {
        label: "Bidder",
        shouldLimit: true,
    },
    floor_meta_strategy: {
        label: "Meta Strategy",
        limitedToAdminOrDev: true,
        uiGroup: "floor",
    },
    floor_strategy: {
        label: "Strategy",
        limitedToAdminOrDev: true,
        uiGroup: "floor",
    },
    datacenter: {
        label: "Data Center",
    },
    bidder_mapped: {
        label: "Bidder (mapped)",
        shouldLimit: true,
    },
    country: {
        label: "Country",
        shouldLimit: true,
    },
    publisher_id: {
        label: "Publisher ID",
        shouldLimit: true,
    },
    placement_id: {
        label: "Placement ID",
        shouldLimit: true,
    },
    request_type: {
        label: "Request Type",
    },
    format: {
        label: "Format",
    },
    sizes: {
        label: "Sizes",
        shouldLimit: true,
    },
    platform: {
        label: "Platform",
    },
    bundle: {
        label: "Bundle",
        shouldLimit: true,
    },
    os: {
        label: "Operating System",
    },
    browser: {
        label: "Browser",
    },
    device: {
        label: "Device",
    },
    codebase_version: {
        label: "ML Codebase Version",
    },
    version: {
        label: "Version",
    },
    device_language: {
        label: "Device Language",
    },
    utc_offset: {
        label: "UTC Offset",
        row_type: "Number",
    },
    gdpr: {
        label: "GDPR",
        row_type: "Number",
    },
    us_privacy: {
        label: "US Privacy",
    },
    video_startdelay: {
        label: "Video Start Delay",
        row_type: "Number",
    },
    video_skip: {
        label: "Video Skip",
        row_type: "Number",
    },
    video_rewarded: {
        label: "Video Rewarded",
        row_type: "Number",
    },
    url: {
        label: "URL",
        shouldLimit: true,
    },
    domain: {
        label: "Domain",
        shouldLimit: true,
    },
    custom1: {
        label: "Custom 1",
        shouldLimit: true,
    },
    custom2: {
        label: "Custom 2",
        shouldLimit: true,
    },
    custom3: {
        label: "Custom 3",
        shouldLimit: true,
    },
    custom4: {
        label: "Custom 4",
        shouldLimit: true,
    },
    custom5: {
        label: "Custom 5",
        shouldLimit: true,
    },
    regression_saveAt: {
        label: "Regression ML Saved At",
        shouldLimit: false,
        limitedToAdminOrDev: true,
        uiGroup: "floor",
    },
    regression_buildCommit: {
        label: "Regression ML Build Commit",
        shouldLimit: false,
        limitedToAdminOrDev: true,
        uiGroup: "floor",
    },
    regression_buildVersion: {
        label: "Regression ML Build Version",
        shouldLimit: false,
        limitedToAdminOrDev: true,
        uiGroup: "floor",
    },
    regression_commitDate: {
        label: "Regression ML Commit Date",
        shouldLimit: false,
        limitedToAdminOrDev: true,
        uiGroup: "floor",
    },
    curr_as_auction_history: {
        label: "State As Auction History",
        limitedToAdminOrDev: true,
        uiGroup: "ml",
    },
    auction_history1: {
        label: "Result Hist. 1",
        limitedToAdminOrDev: true,
        uiGroup: "ml",
    },
    auction_history2: {
        label: "Result Hist. 2",
        limitedToAdminOrDev: true,
        uiGroup: "ml",
    },
    auction_history3: {
        label: "Result Hist. 3",
        limitedToAdminOrDev: true,
        uiGroup: "ml",
    },
    auction_history4: {
        label: "Result Hist. 4",
        limitedToAdminOrDev: true,
        uiGroup: "ml",
    },
    video_playback_method: {
        label: "Video Playback Method",
    },
    site_cat: {
        label: "Size Category",
        shouldLimit: true,
    },
    unit_types: {
        label: "Unit Types",
    },
    page_cat: {
        label: "Page Category",
    },
    has_gps: {
        label: "Has GPS",
        row_type: "Number",
    },
    has_omsdk: {
        label: "Has OMSDK",
        row_type: "Number",
    },
    has_age: {
        label: "Has Age",
        row_type: "Number",
    },
    has_gender: {
        label: "Has Gender",
        row_type: "Number",
    },
    has_mraid: {
        label: "Has MRaid",
        row_type: "Number",
    },
    has_vast: {
        label: "Has Vast",
        row_type: "Number",
    },
    has_vpaid: {
        label: "Has VPaid",
        row_type: "Number",
    },
    has_consent: {
        label: "Has Consent",
        row_type: "Number",
    },
    is_qps: {
        label: "Is QPS",
        row_type: "Number",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type RequestReductionServerSamplesProfileDimensionsIds = keyof typeof RequestReductionServerSamplesProfile;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Last 24 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities([
        "by_minute",
        "by_10_minutes",
        "by_hour",
        "by_day",
        "by_week",
        "by_month",
        "by_quarter",
        "no_granularity",
    ])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(RequestReductionServerSamplesProfileMetricsIds)
    .withCalculatedMetrics({
        bids: {
            label: "Bids",
            explanation: "Bids",
            formula: m => m.bids_raw,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },

        bid_revenue: {
            label: "Bid revenue",
            explanation: "Bid revenue",
            formula: m => m.bid_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        bid_rate: {
            label: "Bid rate",
            explanation: "Bid Responses from Bidder / Bid Requests to Bidder",
            formula: m => m.bids / m.requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 4,
            objective: OptimizationObjective.Maximize,
        },
        bid_cpm: {
            label: "Bid CPM",
            explanation: "Avg. CPM of Bid Responses from Bidder",
            formula: m => (m.bid_revenue / m.bids) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },

        wins: {
            label: "Wins",
            explanation: "Wins",
            formula: m => m.wins_raw,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        win_revenue: {
            label: "Win revenue",
            explanation: "Win revenue",
            formula: m => m.win_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        win_rate: {
            label: "Win rate",
            explanation: "Impression Events from Bidder / Bid Requests to Bidder",
            formula: m => m.wins / m.requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 6,
            objective: OptimizationObjective.Maximize,
        },
        win_cpm: {
            label: "Win CPM",
            explanation: "Avg. CPM of Impression Events from Bidder (Revenue)",
            formula: m => (m.win_revenue / m.wins) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        requests: {
            label: "Requests",
            explanation: "Total requests",
            formula: m => m.requests_raw,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_rpmm: {
            label: "Request RPMM",
            explanation: "Revenue / Samples * 1000 * 1000",
            formula: m => (m.win_revenue / m.requests) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        floor_raw_model_sum: {
            label: "Floor Raw Model Sum",
            formula: m => m.floor_raw_model_sum / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        floor_raw_model_sum_cpm: {
            label: "Floor Raw Model CPM",
            formula: m => (m.floor_raw_model_sum / m.requests) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        floor_sum: {
            label: "Floor Sum",
            formula: m => m.floor_sum / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        floor_sum_cpm: {
            label: "Floor CPM",
            formula: m => (m.floor_sum / m.requests) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_bid_sum_sum: {
            label: "Feature 'bid_sum' Sum",
            formula: m => m.feature_bid_sum_sum / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_bid_sum_avg: {
            label: "Feature 'bid_sum' Avg MM",
            formula: m => (m.feature_bid_sum_sum / m.requests) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_bid_cpm_cache_sum: {
            label: "Feature 'bid_cpm_cache' Sum",
            formula: m => m.feature_bid_cpm_cache_sum / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_bid_cpm_cache_avg: {
            label: "Feature 'bid_cpm_cache' Avg MM",
            formula: m => (m.feature_bid_cpm_cache_sum / m.requests) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_custom_num_1_sum: {
            label: "Custom Num 1 Sum",
            formula: m => m.feature_custom_num_1_sum,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_custom_num_1_avg: {
            label: "Custom Num 1 Avg",
            formula: m => m.feature_custom_num_1_sum / m.requests,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_custom_num_2_sum: {
            label: "Custom Num 2 Sum",
            formula: m => m.feature_custom_num_2_sum,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_custom_num_2_avg: {
            label: "Custom Num 2 Avg",
            formula: m => m.feature_custom_num_2_sum / m.requests,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_custom_num_3_sum: {
            label: "Custom Num 3 Sum",
            formula: m => m.feature_custom_num_3_sum,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        feature_custom_num_3_avg: {
            label: "Custom Num 3 Avg",
            formula: m => m.feature_custom_num_3_sum / m.requests,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        session_bids_sum: {
            label: "Session Bids Sum",
            formula: m => m.session_bids_sum,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        session_requests_sum: {
            label: "Session Requests Sum",
            formula: m => m.session_requests_sum,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
        session_bid_rate: {
            label: "Session Bid Rate",
            formula: m => m.session_bids_sum / m.session_requests_sum,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 4,
            objective: OptimizationObjective.Maximize,
            limitedToAdminOrDev: true,
        },
    })
    .withInitialMetrics(["requests", "win_rate", "bid_rate", "win_cpm", "bid_cpm"])
    .withMetricTable({
        tabMeta: {
            overall: { name: "Overall", explanation: "Sample report metrics" },
        },
        columnNames: {
            profile: "Profile",
            floor: "Floor",
            ml: "ML",
        },
        tableMapping: {
            overall: {
                profile: [
                    "requests",
                    "win_rate",
                    "bid_rate",
                    "wins",
                    "bids",
                    "win_revenue",
                    "bid_revenue",
                    "win_cpm",
                    "bid_cpm",
                    "request_rpmm",
                ],
                floor: [
                    //
                    "floor_sum",
                    "floor_sum_cpm",
                    "floor_raw_model_sum",
                    "floor_raw_model_sum_cpm",
                ],
                ml: [
                    "session_requests_sum",
                    "session_bids_sum",
                    "session_bid_rate",
                    "feature_bid_sum_sum",
                    "feature_bid_sum_avg",
                    "feature_bid_cpm_cache_sum",
                    "feature_bid_cpm_cache_avg",
                    "feature_custom_num_1_sum",
                    "feature_custom_num_1_avg",
                    "feature_custom_num_2_sum",
                    "feature_custom_num_2_avg",
                    "feature_custom_num_3_sum",
                    "feature_custom_num_3_avg",
                ],
            },
        },
    })
    .withDimensions(RequestReductionServerSamplesProfile);
