export const BOOK_CALL_LINK = "/schedule-meeting";

export const SIGNUP_LINK = "https://suite.assertiveyield.com";

export const CONTACT_US_LINK = "/contact-us/";

export const HUBSPOT_FORM_ID = "b2d0b1ea-7c81-442f-815b-cea47131477c";

export const HUBSPOT_PORTAL_ID = "26553047";

export const RECAPTCHA_KEY = "6Lf2nHMlAAAAAC4D_J2xt8Wx2zq1A8t1k7RrTMcM";
