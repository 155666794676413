import { type PublicDimensionConfig } from "../../types";

export const YieldWebDimensions = {
    content_headline: {
        label: "Content Headline",
        shouldLimit: true,
        uiGroup: "content",
    },
    content_author: {
        label: "Content Author",
        shouldLimit: true,
        uiGroup: "content",
    },
    content_label: {
        label: "Content Label",
        row_type: "String",
        uiGroup: "content",
        hideFromApiDocs: true,
    },
    content_datePublished: {
        label: "Content Date Published",
        shouldLimit: true,
        uiGroup: "content",
    },
    topics: {
        label: "Topics",
        uiGroup: "user",
    },
    content_dateModified: {
        label: "Content Date Modified",
        shouldLimit: true,
        uiGroup: "content",
    },
} satisfies Record<string, Omit<PublicDimensionConfig, "id">>;
