import type { FC } from "react";
import { Container } from "react-bootstrap";
import { InputField, useEmailSubscribe } from "ay-md-docs-render/src/SubscribeInput";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const SubscribeToAyNews: FC<{ text_color?: string; title: string }> = ({ text_color, title }) => {
    const { email, setEmail, onSubscribe, isEmailValid, hasSubscribed } = useEmailSubscribe();
    return (
        <Container className={styles.container}>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/subscribe-to-ay-news-second-glow.svg" alt="" />
            </div>

            <div className="d-flex flex-column align-items-center">
                <div className={["figma-h2 mb-1", text_color ? text_color : styles.black_text].join(" ")}>{title}</div>
                <div className={[text_color ? text_color : styles.black_text, "mb-4"].join(" ")}>
                    Get the coolest, most insightful and latest digital advertising ecosystem delivered to your inbox
                    each month.
                </div>
                {hasSubscribed ? (
                    <div className={[text_color ? text_color : styles.black_text, "mb-4"].join(" ")}>
                        Thanks for subscribing
                    </div>
                ) : (
                    <div className="d-flex flex-column flex-xl-row w-100 justify-content-center">
                        <div className={styles.input_container}>
                            <InputField
                                value={email}
                                setValue={setEmail}
                                isValid={isEmailValid}
                                placeholder="Email"
                                errorText="Please provide a valid email"
                            />
                        </div>
                        <div className={styles.buttonWrapper}>
                            <button
                                className={[styles.subscribe_button, "mt-2 mt-xl-0 ms-xl-2"].join(" ")}
                                onClick={onSubscribe}
                            >
                                Subscribe
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default SubscribeToAyNews;
