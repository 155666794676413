import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig } from "../types";

const RAW_METRICS = {
    samples: {},
    bids: {},
    wins: {},
    revenue: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawTrafficShapingServerFlooringMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "samples",
    "bids",
    "bid_rate",
    "bid_cpm",
    "wins",
    "win_rate",
    "win_cpm",
    "revenue",
    "request_rpmm",
] as const;
export type TrafficShapingServerFlooringMetrics = (typeof CALC_IDS)[number];
const DIMENSIONS = {
    datacenter: {
        label: "Data Center",
    },
    bidder_id: {
        label: "Bidder Id",
    },
    node: {
        label: "Node",
    },
    version: {
        label: "Version",
    },
    commit: {
        label: "Commit",
    },
    meta_strategy: {
        label: "Meta Strategy",
    },
    strategy: {
        label: "Strategy",
    },
    os: {
        label: "OS",
    },
    country: {
        label: "Country",
        shouldLimit: true,
    },
    hour: {
        label: "Hour (UTC)",
    },
    format: {
        label: "Format",
    },
    platform: {
        label: "Platform",
    },
    publisher_id: {
        label: "Publisher Id",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type TrafficShapingServerFlooringDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Last 24 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities(["by_hour", "by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        revenue: {
            label: "Revenue",
            formula: m => m.revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        samples: {
            label: "Samples",
            formula: m => m.samples,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        bids: {
            label: "Bids",
            formula: m => m.bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        bid_cpm: {
            label: "Bid CPM",
            formula: m => (m.revenue / m.bids) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        bid_rate: {
            label: "Bid Rate",
            formula: m => m.bids / m.samples,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        wins: {
            label: "Wins",
            formula: m => m.wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        win_cpm: {
            label: "Win CPM",
            formula: m => (m.revenue / m.wins) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        win_rate: {
            label: "Win Rate",
            formula: m => m.wins / m.samples,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
            decimalPlaces: 6,
        },
        request_rpmm: {
            label: "Request RPMM",
            formula: m => (m.revenue / m.samples) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
    })
    .withInitialMetrics(["revenue", "samples", "request_rpmm"])
    .withMetricTable({
        columnNames: {
            global: "Global",
        },
        tabMeta: {
            overall: { name: "Overall", explanation: "Overall metrics" },
        },
        tableMapping: {
            overall: {
                global: [...CALC_IDS],
            },
        },
    })
    .withDimensions(DIMENSIONS);
