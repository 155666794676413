import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import { CalculationType, OptimizationObjective, type PublicDimensionConfig } from "../types";
import traffic_shaping_server_samples_profile, {
    RequestReductionServerSamplesProfile,
    RequestReductionServerSamplesProfileMetricsIds,
} from "./traffic_shaping_server_samples_profile";

const CALC_IDS = [
    ...RequestReductionServerSamplesProfileMetricsIds,
    "req_reduction_rate",
    "lost_bids_rate",
    "lost_wins_rate",
    "lost_revenue_rate",
    "missed_revenue",
    "predicts",
    "rpb",
    "reduction_efficiency",
    "bid_accuracy",
    "bid_precision",
    "bid_recall",
    "bid_specificity",
    "win_accuracy",
    "win_precision",
    "win_recall",
    "win_specificity",
    "retained_revenue",
    "retained_requests",
    "retained_request_rpmm",
    "retained_bids",
    "retained_wins",
    "retained_bid_rate",
    "retained_win_rate",
    "retained_auction_size",
] as const;
export type RequestReductionServerSamplesPredictMetricsType = (typeof CALC_IDS)[number];

const DIMENSIONS = {
    ...RequestReductionServerSamplesProfile,
    bid_confusion_matrix: {
        label: "Bid Confusion Matrix",
    },
    win_confusion_matrix: {
        label: "Win Confusion Matrix",
    },
    threshold: {
        label: "Threshold",
        row_type: "Number",
        isMultiAttribution: true,
        specialSort: data =>
            data.sort((a, b) => {
                const da = parseFloat(a as any);
                const db = parseFloat(b as any);
                return da - db;
            }),
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type RequestReductionServerSamplesPredictDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.dateTime)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Last 24 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
    ])
    .withTimeGranularities([
        "by_minute",
        "by_10_minutes",
        "by_hour",
        "by_day",
        "by_week",
        "by_month",
        "by_quarter",
        "no_granularity",
    ])
    .withRawMetrics(traffic_shaping_server_samples_profile.rawMetrics)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        ...traffic_shaping_server_samples_profile.metrics,
        reduction_efficiency: {
            label: "Reduction Efficiency",
            explanation: "Reduction Efficiency correlates the reduction rate with revenue loss. (higher is better)",
            formula: m => 1 - m.req_reduction_rate * m.lost_revenue_rate * 100,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },

        req_reduction_rate: {
            label: "Req. Reduction Rate",
            explanation: "Predicted as False / Requests",
            formula: m => (m.bid_true_negative + m.bid_false_negative) / m.requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        lost_bids_rate: {
            label: "Lost Bids Rate",
            explanation: "Reduced / Requests",
            formula: m => m.bid_false_negative / m.bids,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        lost_wins_rate: {
            label: "Lost Wins Rate",
            explanation: "Missed Wins / Wins",
            formula: m => m.win_false_negative / m.wins,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        lost_revenue_rate: {
            label: "Lost Revenue Rate",
            explanation: "Missed Revenue / Revenue",
            formula: m => m.missed_revenue / m.win_revenue,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        missed_revenue: {
            label: "Missed Revenue",
            explanation: "Missed Revenue",
            formula: m => m.missed_revenue / 1000,
            calculationType: CalculationType.Sum,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        predicts: {
            label: "Predicted as True",
            explanation: "Predicted as True",
            formula: m => m.retained_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        rpb: {
            label: "Revenue per Billion",
            explanation: "Revenue per Billion Wins",
            formula: m => (m.win_revenue / m.wins) * 1_000_000_000,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        // accuracy - shows how often the classifier is correct
        bid_accuracy: {
            label: "Bid Accuracy %",
            explanation: "How often the classifier is correct",
            formula: m => 100 * ((m.bid_true_positive + m.bid_true_negative) / m.requests),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        // precision - shows when classifying positives, how often is correct
        bid_precision: {
            label: "Bid Precision %",
            explanation: "When classifying positives, how often is correct",
            formula: m => 100 * (m.bid_true_positive / (m.bid_true_positive + m.bid_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        // recall how - often the classifier detects actual positives
        bid_recall: {
            label: "Bid Recall %",
            explanation: "How often the classifier detects actual positives",
            formula: m => 100 * (m.bid_true_positive / (m.bid_true_positive + m.bid_false_negative)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        // specificity - how often does the classifier detects actual negatives
        bid_specificity: {
            label: "Bid Specificity %",
            explanation: "How often does the classifier detects actual negatives",
            formula: m => 100 * (m.bid_true_negative / (m.bid_true_negative + m.bid_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        win_accuracy: {
            label: "Win Accuracy %",
            explanation: "How often the classifier is correct",
            formula: m => 100 * ((m.win_true_positive + m.win_true_negative) / m.requests),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        win_precision: {
            label: "Win Precision %",
            explanation: "When classifying positives, how often is correct",
            formula: m => 100 * (m.win_true_positive / (m.win_true_positive + m.win_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        win_recall: {
            label: "Win Recall %",
            explanation: "How often the classifier detects actual positives",
            formula: m => 100 * (m.win_true_positive / (m.win_true_positive + m.win_false_negative)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        win_specificity: {
            label: "Win Specificity %",
            explanation: "How often does the classifier detects actual negatives",
            formula: m => 100 * (m.win_true_negative / (m.win_true_negative + m.win_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        retained_revenue: {
            label: "Retained Revenue",
            explanation: "Retained Revenue",
            formula: m => m.retained_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        retained_requests: {
            label: "Retained Requests",
            explanation: "Retained Requests",
            formula: m => m.retained_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        retained_bids: {
            label: "Retained Bids",
            formula: m => m.bid_true_positive,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        retained_wins: {
            label: "Retained Wins",
            formula: m => m.win_true_positive,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        retained_request_rpmm: {
            label: "Retained Request RPMM",
            explanation: "Retained RPMM",
            formula: m => (m.retained_revenue / m.retained_requests) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        retained_bid_rate: {
            label: "Retained Bid Rate",
            formula: m => m.bid_true_positive / m.retained_requests,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        retained_win_rate: {
            label: "Retained Win Rate",
            formula: m => m.win_true_positive / m.retained_requests,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        retained_auction_size: {
            label: "Avg. Retained Auction Size",
            formula: m => m.non_zero_count / m.requests_raw,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.NoObjective,
        },
    })
    .withInitialMetrics([
        "req_reduction_rate",
        "retained_request_rpmm",
        "reduction_efficiency",
        "lost_bids_rate",
        "lost_wins_rate",
        "lost_revenue_rate",
        "requests",
        "win_rate",
        "bid_rate",
        "win_cpm",
        "bid_cpm",
    ])
    .withMetricTable({
        tabMeta: {
            overall: { name: "Overall", explanation: "Sample report metrics" },
        },
        columnNames: {
            profile: "Profile",
            predictions: "Predictions",
            floor: "Floor",
            ml: "ML",
        },
        tableMapping: {
            overall: {
                profile: [
                    ...(traffic_shaping_server_samples_profile.metricTable.tableMapping.overall.profile ?? []),
                    "rpb",
                    "reduction_efficiency",
                ],

                predictions: [
                    "req_reduction_rate",
                    "lost_bids_rate",
                    "lost_wins_rate",
                    "lost_revenue_rate",
                    "missed_revenue",
                    "predicts",
                    "bid_accuracy",
                    "bid_precision",
                    "bid_recall",
                    "bid_specificity",
                    "win_accuracy",
                    "win_precision",
                    "win_recall",
                    "win_specificity",
                    "retained_revenue",
                    "retained_requests",
                    "retained_bids",
                    "retained_wins",
                    "retained_request_rpmm",
                    "retained_bid_rate",
                    "retained_win_rate",
                    "retained_auction_size",
                ],
                floor: [...(traffic_shaping_server_samples_profile.metricTable.tableMapping.overall.floor ?? [])],
                ml: [...(traffic_shaping_server_samples_profile.metricTable.tableMapping.overall.ml ?? [])],
            },
        },
    })
    .withDimensions(DIMENSIONS);
