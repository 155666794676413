import type { PublicRawMetricConfig } from "../../raw-metrics.service";
import pre from "./yield.time-config";
export const clickBounceThreshold = 10000;

const RAW_METRICS = {
    blocked_impressions: {},
    impressions_filled: {},
    prebid_cached_count: {},
    ad_unfilled_count: {},
    has_dfp_amazonBid_count: {
        hideFromApi: true,
    },
    has_dfp_hash_count: {
        hideFromApi: true,
    },
    ad_unfilled_with_prebid_bids_count: {
        hideFromApi: true,
    },
    prebid_user_id_providers_sum_uses: {},
    prebid_user_id_providers_zero_usages: {},
    vitals_lcp_measurable: {},
    vitals_lcp_sum: {},
    vitals_lcp_good: {},
    vitals_lcp_medium: {},
    vitals_lcp_bad: {},
    vitals_fid_measurable: {},
    vitals_fid_sum: {},
    vitals_fid_good: {},
    vitals_fid_medium: {},
    vitals_fid_bad: {},
    vitals_cls_measurable: {},
    vitals_cls_sum: {},
    vitals_cls_good: {},
    vitals_cls_medium: {},
    vitals_cls_bad: {},
    vitals_inp_measurable: {},
    vitals_inp_sum: {},
    vitals_inp_good: {},
    vitals_inp_medium: {},
    vitals_inp_bad: {},
    vitals_ttfb_measurable: {},
    vitals_ttfb_sum: {},
    vitals_ttfb_good: {},
    vitals_ttfb_medium: {},
    vitals_ttfb_bad: {},
    vitals_fcp_measurable: {},
    vitals_fcp_sum: {},
    vitals_fcp_good: {},
    vitals_fcp_medium: {},
    vitals_fcp_bad: {},
    min_timestamp: {},
    max_timestamp: {},
    ivt_measurable_impressions: {},
    givt_impressions: {},
    sivt_impressions: {},
    impressions: {},
    impressions_unfilled: {},
    impressions_lineitem_house: {},
    pageViews: {},
    pageViewsExact: {
        hideFromApi: true,
    },
    sessions: {},
    sessions_with_bounced_clicks: {
        // following clicksBounced: user returned to the site within 10 seconds -> click bounced / miss click
        config: {
            clickBounceThreshold,
        },
    },
    session_starts: {},
    pageView_starts: {},
    first_five_indicator: {},
    viewable: {},
    viewable_measurable_impressions: {},
    clicks: {},
    clicksBounced: {
        // user returned to the site within 10 seconds -> click bounced / miss click
        config: {
            clickBounceThreshold,
        },
    },
    clicksReturned: {
        // user returned to the site
    },
    prebid_revenue: {},
    prebid_won_impressions: {},
    prebid_won_revenue: {},
    prebid_lost_impressions: {},
    prebid_lost_revenue: {},
    prebid_incremental_value: {},
    nativo_revenue: {},
    nativo_impressions: {},
    outbrain_revenue: {},
    outbrain_impressions: {},
    taboola_revenue: {},
    taboola_impressions: {},
    yahooGemini_revenue: {},
    yahooGemini_impressions: {},
    aps_revenue: {},
    aps_impressions: {},
    adSense_revenue: {},
    adSense_impressions: {},
    ayMediation_revenue: {},
    ayMediation_impressions: {},
    dynamicAllocation_impressions: {},
    dynamicAllocation_predicted_impressions: {},
    dynamicAllocation_revenue: {},
    dynamicAllocation_revenue_sum_of_squares: {
        hideFromApi: true,
    },
    dynamicAllocation_revenue_with_forecast: {},
    dynamicAllocation_revenue_with_forecast_client: {},
    dynamicAllocation_predicted_revenue: {},
    dynamicAllocation_predicted_revenue_residual: {
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_abs_difference: {
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_server: {},
    dynamicAllocation_predicted_revenue_server_residual: {
        // from https://en.wikipedia.org/wiki/Coefficient_of_determination
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_server_abs_difference: {
        hideFromApi: true,
    },
    dynamicAllocation_confirmed_clicks: {},
    direct_impressions: {},
    direct_revenue: {},
    direct_impressions_legacy: {
        hideFromApi: true,
    },
    direct_revenue_legacy: {
        hideFromApi: true,
    },
    lineItem_revenue_legacy: {
        hideFromApi: true,
    },
    lineItem_revenue: {},
    acquisition_cost: {},
    acquisition_cost_measurable_session_starts: {},
    floor: {},
    floor_empirical_revenue: {
        hideFromApi: true,
    },
    floor_measurable_impressions: {},
    uniqueExternalId: {},
} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawYieldMetrics = keyof typeof RAW_METRICS;
export default pre.withRawMetrics(RAW_METRICS);
