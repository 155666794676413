import { CustomRangeConfig } from "../../codecs/custom-range-config.codec";
import { EdaPlacementBuilder } from "../lib";
import type { PublicRawMetricConfig } from "../raw-metrics.service";
import { type PublicDimensionConfig } from "../types";

const RAW_METRICS = {} as const satisfies Record<string, PublicRawMetricConfig>;
export type RawTrafficShapingServerReductionOverview = keyof typeof RAW_METRICS;

const CALC_IDS = [] as const;
export type TrafficShapingServerReductionOverviewMetrics = (typeof CALC_IDS)[number];
const DIMENSIONS = {
    bidderID: {
        label: "Bidder ID",
    },
    datacenter: {
        label: "Datacenter",
    },
} as const satisfies Record<string, Omit<PublicDimensionConfig, "id">>;

export type TrafficShapingServerReductionOverviewDimensionsIds = keyof typeof DIMENSIONS;

export default new EdaPlacementBuilder()
    .withCustomRange(CustomRangeConfig.off)
    .withTimeRanges([
        "Last 30 Minutes",
        "Last 1 Hour",
        "Last 6 Hours",
        "Last 12 Hours",
        "Last 24 Hours",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
    ])
    .withTimeGranularities(["by_minute", "by_10_minutes", "by_hour", "by_day", "by_week", "by_month", "by_quarter"])
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({})
    .withInitialMetrics([])
    .withMetricTable({
        columnNames: {
            global: "Global",
        },
        tabMeta: {
            overall: { name: "Overall", explanation: "Overall metrics" },
        },
        tableMapping: {
            overall: {
                global: [...CALC_IDS],
            },
        },
    })
    .withDimensions(DIMENSIONS);
