// extracted by mini-css-extract-plugin
export var agreeToProcessData = "styles-module--agreeToProcessData--5ac1c";
export var agreeToProcessDataCheckbox = "styles-module--agreeToProcessDataCheckbox--6c90c";
export var agreeToProcessDataText = "styles-module--agreeToProcessDataText--31703";
export var block = "styles-module--block--b3711";
export var consentToReceiveCommunications = "styles-module--consentToReceiveCommunications--3673f";
export var consentToReceiveCommunicationsCheckbox = "styles-module--consentToReceiveCommunicationsCheckbox--e3165";
export var consentToReceiveCommunicationsText = "styles-module--consentToReceiveCommunicationsText--01551";
export var ctaWrapper = "styles-module--ctaWrapper--b35b8";
export var description = "styles-module--description--cdf54";
export var emailInputWrapper = "styles-module--emailInputWrapper--34064";
export var message = "styles-module--message--655f0";
export var supTitle = "styles-module--supTitle--6fc64";
export var thanks = "styles-module--thanks--ca452";
export var title = "styles-module--title--82ac2";
export var userCredential = "styles-module--userCredential--9b240";
export var userCredentialsWrapper = "styles-module--userCredentialsWrapper--5ef3c";
export var wrapper = "styles-module--wrapper--61077";