import { InputField, useEmailSubscribe } from "ay-md-docs-render/src/SubscribeInput";
import { Link } from "gatsby";
import CustomButton from "components/CustomButton";
import * as styles from "./styles.module.scss";

export const SubscribeToAyNewsletters = () => {
    const {
        email,
        setEmail,
        onSubscribe,
        isEmailValid,
        isFirstNameValid,
        isLastNameValid,
        hasSubscribed,
        firstName,
        lastName,
        setFirstName,
        setLastName,
        isAgreeToProcessData,
        setIsAgreeToProcessData,
        isAgreeToProcessDataValid,
    } = useEmailSubscribe();

    return (
        <div className="container">
            <div className={styles.wrapper}>
                <div className={styles.block}>
                    <h2 className={styles.title}>Subscribe to AY Newsletters</h2>
                    <p className={styles.description}>
                        Get the coolest, most insightful and latest digital advertising ecosystem delivered to your
                        inbox each month.
                    </p>
                </div>
                <div className={styles.block}>
                    {hasSubscribed ? (
                        <div className={styles.thanks}>Thanks for subscribing!</div>
                    ) : (
                        <div className={styles.ctaWrapper}>
                            <div className={styles.userCredentialsWrapper}>
                                <div className={styles.userCredential}>
                                    <p className={styles.supTitle}>First name</p>
                                    <InputField
                                        value={firstName}
                                        setValue={setFirstName}
                                        isValid={isFirstNameValid}
                                        errorText="Please provide a valid first name"
                                        placeholder="Enter your first name"
                                    />
                                </div>
                                <div className={styles.userCredential}>
                                    <p className={styles.supTitle}>Last name</p>
                                    <InputField
                                        value={lastName}
                                        setValue={setLastName}
                                        isValid={isLastNameValid}
                                        errorText="Please provide a valid last name"
                                        placeholder="Enter your last name"
                                    />
                                </div>
                            </div>
                            <div className="mb-4 mb-lg-2">
                                <p className={styles.supTitle}>Email</p>
                                <div className={styles.emailInputWrapper}>
                                    <InputField
                                        value={email}
                                        setValue={setEmail}
                                        isValid={isEmailValid}
                                        errorText="Please provide a valid email"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <p className={styles.message}>We care about your data in our privacy policy.</p>
                            </div>

                            <label className={`${styles.agreeToProcessData} mb-4`} htmlFor="agreeToProcessData">
                                <input
                                    className={styles.agreeToProcessDataCheckbox}
                                    type="checkbox"
                                    id="agreeToProcessData"
                                    checked={isAgreeToProcessData}
                                    onChange={() => setIsAgreeToProcessData(prev => !prev)}
                                />
                                <p className={styles.agreeToProcessDataText}>
                                    By checking this box, you agree to the processing of your personal data by Assertive
                                    Yield. See here our <Link to="/privacy">Privacy Statement</Link>,{" "}
                                    <Link to="/terms">Terms</Link> and <Link to="/disclaimer">Disclaimer</Link>.
                                </p>
                            </label>

                            {!isAgreeToProcessDataValid && (
                                <p className="invalid_text mb-4">You must agree to proceed</p>
                            )}
                            <div>
                                <CustomButton buttonSize="btn--large" onClick={onSubscribe}>
                                    Subscribe
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubscribeToAyNewsletters;
