// @ts-ignore
import React, { type FC, useRef, useState } from "react";

export const InputField: FC<{
    value: string;
    setValue: (val: string) => void;
    placeholder: string;
    isValid: boolean;
    errorText: string;
}> = ({ value, setValue, placeholder, isValid, errorText }) => (
    <div className="subscribeToUpdates">
        <input
            className={`subscribe_input ${isValid ? "" : "invalid"}`}
            placeholder={placeholder}
            value={value}
            onChange={e => setValue(e.target.value)}
        />
        {!isValid && <span className="invalid_text">{errorText}</span>}
    </div>
);

const FORM_ID = "0433dcd1-a8c4-4caf-ae83-1e5934aaaabf";
const PORTAL_ID = "26553047";

const isEmailValid = (email: string) => {
    // https://stackoverflow.com/a/46181
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const isNameValid = (name: string) => {
    if (name.length === 0) {
        return false;
    }

    return true;
};

const isAgreeToProcessValid = (agreeToProcess: boolean) => {
    return agreeToProcess;
};

const getCookie = (cookiename: string) => {
    // Get name followed by anything except a semicolon
    const cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
    // Return everything after the equal sign, or an empty string if the cookie name not found
    return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
};

const doPostRequest = (email: string, firstName: string, lastName: string) => {
    const hutk = getCookie("hubspotutk");
    const pageUri = String(document.location);
    const pageName = String(document.title);

    const body = {
        fields: [
            {
                objectTypeId: "0-1",
                name: "email",
                value: email,
            },
            {
                objectTypeId: "0-1",
                name: "firstname",
                value: firstName,
            },
            {
                objectTypeId: "0-1",
                name: "lastname",
                value: lastName,
            },
        ],
        context: {
            hutk,
            pageUri,
            pageName,
        },
        legalConsentOptions: {
            consent: {
                consentToProcess: true,
                text: "I agree to allow Assertive Yield to store and process my personal data.",
                communications: [
                    {
                        value: true,
                        subscriptionTypeId: 999,
                        text: "I agree to receive other communications from AY.",
                    },
                ],
            },
        },
    };

    window
        .fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(it => it.text())
        // eslint-disable-next-line no-console
        .then(console.log)
        // eslint-disable-next-line no-console
        .catch(console.error);
};

export const useEmailSubscribe = () => {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [isAgreeToProcessData, setIsAgreeToProcessData] = useState(false);
    const [isEmailValidState, setIsEmailValid] = useState(true);
    const [isAgreeToProcessDataValid, setIsAgreeToProcessDataValid] = useState(true);
    const [isFirstNameValid, setIsFirstNameValid] = useState(true);
    const [isLastNameValid, setIsLastNameValid] = useState(true);
    const touchedSubmit = useRef(false);
    const [hasSubscribed, setHasSubscribed] = useState(false);

    const validateFields = () => {
        const emailValid = isEmailValid(email);
        const firstNameValid = isNameValid(firstName);
        const lastNameValid = isNameValid(lastName);
        const agreeToProcessDataValid = isAgreeToProcessValid(isAgreeToProcessData);
        setIsEmailValid(emailValid);
        setIsFirstNameValid(firstNameValid);
        setIsLastNameValid(lastNameValid);
        setIsAgreeToProcessDataValid(agreeToProcessDataValid);
        return emailValid && firstNameValid && lastNameValid && agreeToProcessDataValid;
    };

    const onSubscribe = () => {
        touchedSubmit.current = true;
        if (validateFields()) {
            doPostRequest(email, firstName, lastName);
            setHasSubscribed(true);
        }
    };

    return {
        email,
        setEmail,
        isEmailValid: isEmailValidState,
        firstName,
        setFirstName,
        isFirstNameValid,
        lastName,
        setLastName,
        isLastNameValid,
        isAgreeToProcessData,
        setIsAgreeToProcessData,
        onSubscribe,
        hasSubscribed,
        isAgreeToProcessDataValid,
    };
};
