import type { DimensionKeys } from "../constants/dimension-keys.constants";
import type { IssStat } from "../utils/iss";
import type { AddRevenueIndexMetricsType, RawAdRevenueMetrics } from "./data/ad_revenue";
import type {
    DiscrepancyRevenueReportMetricsType,
    DiscrepancyRevenueReportRawMetrics,
} from "./data/discrepancy_revenue";
import type { ErrorAnalyticsMetrics, RawErrorAnalyticsMetrics } from "./data/error_analytics";
import type { FinancialReportMetrics, FinancialReportRawMetrics } from "./data/financial";
import type { IndustryWideDataMetricsType, RawIndustryMetrics } from "./data/industry";
import type { PBSAccountUsageReportMetrics, PBSAccountUsageReportRawMetrics } from "./data/pbs_account_usage";
import type { PrebidMetricsType, RawPrebidMetrics } from "./data/prebid";
import type {
    RawRequestReductionClientV2Metrics,
    RequestReductionClientMetricsType,
} from "./data/request_reduction_client_v2";
import type {
    RequestReductionServerLatencyMetricsType,
    RequestReductionServerLatencyRawMetrics,
} from "./data/request_reduction_server_latency";
import type { BuyReportMetricsType } from "./data/shared/buy";
import type { SiteReportMetricsType } from "./data/site";
import type { SpentBySectionReportMetricsType } from "./data/spent_by_section";
import type {
    RawTrafficShapingServerFlooringMetrics,
    TrafficShapingServerFlooringMetrics,
} from "./data/traffic_shaping_server_floor_bandit";
import type {
    RawTrafficShapingServerReductionOverview,
    TrafficShapingServerReductionOverviewMetrics,
} from "./data/traffic_shaping_server_reduction_overview";
import type { RawTrafficShapingServerRPMM, TrafficShapingServerRPMMMetrics } from "./data/traffic_shaping_server_rpmm";
import type { RequestReductionServerSamplesPredictMetricsType } from "./data/traffic_shaping_server_samples_predict";
import type {
    RawRequestReductionServerSamplesMetrics,
    RequestReductionServerSamplesProfileMetricsType,
} from "./data/traffic_shaping_server_samples_profile";
import type { RawWebMetrics, WebMetrics } from "./data/web";
import type { YieldMetricsType } from "./data/yield/yield.calculated-metrics";
import type { RawYieldMetrics } from "./data/yield/yield.raw-metrics";

export type RawMetricsType =
    | RawRequestReductionClientV2Metrics
    | RawRequestReductionServerSamplesMetrics
    | RawTrafficShapingServerFlooringMetrics
    | RawErrorAnalyticsMetrics
    | RawYieldMetrics
    | RawPrebidMetrics
    | RawWebMetrics
    | DiscrepancyRevenueReportRawMetrics
    | FinancialReportRawMetrics
    | RawAdRevenueMetrics
    | RawIndustryMetrics
    | RequestReductionServerLatencyRawMetrics
    | "clicks_bought"
    | "spent"
    | YieldMetricsType
    | PBSAccountUsageReportRawMetrics
    | RawTrafficShapingServerReductionOverview
    | RawTrafficShapingServerRPMM;

// export type UnionToIntersection<T> = (T extends any ? (x: T) => any : never) extends (x: infer R) => any ? R : never;

export type AggOpNames = "total" | "byDate" | "groupTotal" | "groupByDate";
export type Formula<T extends string> = (
    m: Record<T, number>,
    opname: AggOpNames,
    total: Record<T, number>,
    totalByDate: Record<T, number>
) => number | null;

/**
 * Calculation Type is used to define the chart type that is going to be used
 * to visualize the metric, and to apply statistical significance.
 * We render two variants of charts: "sum" and "division".
 * "sum" charts are rendered as a stacked bar chart.
 * "division" charts are rendered as overlapped line charts.
 * Statistical significance depends on the type of division, which can be either
 * "proportion" or "mean", but we also support a special type "SpecialRate" which should
 * be used when the data required to calculate statistical significance is not provided.
 */
export const CalculationType = {
    /**
     * Always statistically significant.
     * Uses "sum" chart variant.
     */
    Sum: "Sum",
    /**
     * Metrics which can be thought of as a "percentage", or rate.
     * Statistical significance is fully automatic.
     * Uses "division" chart variant.
     */
    ProportionRatio: "ProportionRatio",
    /**
     * Metrics which can be thought of as a "percentage", or rate.
     * Statistical significance is fully automatic.
     * Uses "division" chart variant.
     */
    ProportionPercentage: "ProportionPercentage",
    /**
     * Metrics which are value-based, not a "percentage".
     * Statistical significance uses hard-coded data.
     * It will throw an error in CI if a metric of this type do not inform it's statistical significance data.
     * Uses "division" chart variant.
     */
    Mean: "Mean",
    /**
     * Statistically significance will ignore these metrics, making them always statistically significance.
     * Uses "division" chart variant.
     */
    SpecialRate: "SpecialRate",
} as const;
export type CalculationType = (typeof CalculationType)[keyof typeof CalculationType];

export const OptimizationObjective = {
    Maximize: "Maximize",
    Minimize: "Minimize",
    NoObjective: "NoObjective",
} as const;
export type OptimizationObjective = (typeof OptimizationObjective)[keyof typeof OptimizationObjective];
export interface IMetric<MainKeys extends string, RawKeys extends string> {
    label: string;
    explanation?: string;
    formula: Formula<MainKeys | RawKeys>;
    issStats?: IssStat;
    calculationType: CalculationType;
    objective: OptimizationObjective;
    hideFromApiDocs?: boolean;
    hideFromAlerts?: boolean;
    timeSpan?: number;
    decimalPlaces?: number;
    keepFormulaFalsyAsFalsy?: boolean; // default behavior is to coerce a formula's null to zero, but we need to break this rule for certain metrics
    limitedToAdminOrDev?: boolean;
    isTombstone?: boolean;
    hideTotal?: boolean;
}
export type MainMetricsType =
    | YieldMetricsType
    | WebMetrics
    | ErrorAnalyticsMetrics
    | IndustryWideDataMetricsType
    | AddRevenueIndexMetricsType
    | RequestReductionClientMetricsType
    | RequestReductionServerLatencyMetricsType
    | RequestReductionServerSamplesPredictMetricsType
    | RequestReductionServerSamplesProfileMetricsType
    | BuyReportMetricsType
    | SiteReportMetricsType
    | SpentBySectionReportMetricsType
    | PrebidMetricsType
    | DiscrepancyRevenueReportMetricsType
    | FinancialReportMetrics
    | TrafficShapingServerFlooringMetrics
    | PBSAccountUsageReportMetrics
    | TrafficShapingServerReductionOverviewMetrics
    | TrafficShapingServerRPMMMetrics;

export interface IMetricReturns<MainKeys extends string, RawKeys extends string> extends IMetric<MainKeys, RawKeys> {
    id: MainKeys;
    formulaDependencies: (MainKeys | RawKeys)[];
    rawDependencies: RawKeys[];
    config?: {
        [key: string]: number;
    };
    shareOfVoiceChildren: MainMetricsType[];
}

export type MetricCalculationFormula = {
    formula: Formula<any>;
    keepFormulaFalsyAsFalsy?: boolean;
    id: MainMetricsType;
    rawDependencies: RawMetricsType[];
    formulaDependencies: (RawMetricsType | MainMetricsType)[];
};

type AnyPossibleMetricItem = IMetricReturns<MainMetricsType, RawMetricsType>;

export type AllPossibleMetricsMap = Partial<Record<MainMetricsType, AnyPossibleMetricItem>>;

export type AnyMetric = MainMetricsType;
export type AnyMetricOrRaw = MainMetricsType | RawMetricsType;

export type SelectedDimension = {
    id: DimensionKeys;
    locked: boolean;
};
export const ALL_DIMENSION_UI_GROUP_IN_ORDER = [
    "demand",
    "ad_server",
    "implementation",
    "custom",
    "user",
    "source",
    "content",
    "mapped",
    "time_based",
    "yield_manager",
    "specialized",
    "none",
    "floor",
    "ml",
] as const;
export type DimensionUiGroup = (typeof ALL_DIMENSION_UI_GROUP_IN_ORDER)[number];

export const DimensionUiGroupLabels: Record<DimensionUiGroup, string> = {
    none: "Other",
    demand: "Demand",
    ad_server: "Ad Server",
    implementation: "Implementation",
    custom: "Custom",
    user: "User",
    source: "Source",
    content: "Content",
    mapped: "Mapped",
    time_based: "Time Based",
    yield_manager: "Yield Manager",
    specialized: "Specialized",
    floor: "Floor",
    ml: "ML",
};
export const RenderDimension = {
    PlainString: "PlainString",
    Link: "Link",
} as const;
export type RenderDimension = (typeof RenderDimension)[keyof typeof RenderDimension];
export type PublicDimensionConfig = {
    id: DimensionKeys;
    label: string;
    description?: string;
    row_type?: "String" | "FixedString(2)" | "Number" | "Enum";
    shouldLimit?: boolean;
    enumOptions?: readonly string[];
    isMultiAttribution?: boolean;
    hideFromApiDocs?: boolean;
    isApiOnly?: boolean;
    isTombstone?: boolean;
    uiGroup?: DimensionUiGroup;
    render?: RenderDimension;
    limitedToAdminOrDev?: boolean;
    specialSort?: (it: string[]) => string[];
};
