import { FC, useEffect, useState } from "react";
import Lottie from "lottie-react";
import DarkIcon from "./nonAnimatedLogoDark";
import WhiteIcon from "./nonAnimatedLogoWhite";
import * as styles from "./styles.module.scss";

const ANIM_DATA_DARK = {
    v: "5.12.1",
    fr: 30,
    ip: 0,
    op: 167,
    w: 800,
    h: 280,
    nm: "#AY_loading animation_full logo_st_dark",
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "AY-type",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [526.448, 148.462, 0], ix: 2, l: 2 },
                a: { a: 0, k: [507.576, 140.444, 0], ix: 1, l: 2 },
                s: { a: 0, k: [49.758, 49.758, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [243.969, -61.749],
                                        [228.502, -88.581],
                                        [227.988, -89.477],
                                        [214.693, -89.477],
                                        [214.693, 32.13],
                                        [243.969, 32.176],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: "sh",
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-405.263, -138.193],
                                        [-406.446, -140.193],
                                        [-423.765, -110.188],
                                        [-507.325, 34.468],
                                        [-468.683, 34.468],
                                        [-404.444, -76.731],
                                        [-357.438, 4.737],
                                        [-340.117, -25.314],
                                        [-387.125, -106.734],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 2",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: "sh",
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [1.368, -2.37],
                                        [0, 0],
                                        [0, -0.088],
                                        [0.047, 0.041],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 3.5],
                                        [0, 0],
                                        [-4.219, 0],
                                        [0, 0],
                                        [-1.78, 3.078],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0.092],
                                        [0, -0.047],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [3.453, 0],
                                        [0, 0],
                                        [0, -4.219],
                                        [0, 0],
                                        [3.561, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-2.74, 0],
                                    ],
                                    v: [
                                        [56.948, -84.649],
                                        [48.886, -70.704],
                                        [48.84, -70.477],
                                        [48.793, -70.565],
                                        [38.431, -88.479],
                                        [19.428, -88.479],
                                        [19.428, 32.131],
                                        [42.387, 32.131],
                                        [48.704, 25.766],
                                        [48.704, -50.881],
                                        [56.34, -58.521],
                                        [104.095, -58.521],
                                        [112.719, -63.495],
                                        [118.819, -74.052],
                                        [118.819, -74.055],
                                        [120.15, -76.36],
                                        [120.15, -76.365],
                                        [120.164, -76.383],
                                        [127.146, -88.479],
                                        [63.586, -88.479],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 3",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: "sh",
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0.384, -0.241],
                                        [0.909, -0.501],
                                        [1.224, -0.455],
                                        [1.818, 0],
                                        [1.91, 2.138],
                                        [0, 3.82],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.121, -1.191],
                                        [0, -1.451],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-5.684, -5.953],
                                        [-10.5, 0],
                                        [-5.544, 1.817],
                                        [-3.575, 2.494],
                                        [0, 0],
                                    ],
                                    o: [
                                        [-0.371, 0.097],
                                        [-0.728, 0.408],
                                        [-0.909, 0.454],
                                        [-1.228, 0.412],
                                        [-3.18, 0],
                                        [-1.91, -2.137],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.222, 1.177],
                                        [-0.148, 1.442],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 10.547],
                                        [5.684, 6.004],
                                        [5.958, 0],
                                        [4.321, -1.433],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [176.806, 6.533],
                                        [175.68, 7.034],
                                        [173.269, 8.397],
                                        [170.043, 9.76],
                                        [165.495, 10.399],
                                        [157.902, 7.214],
                                        [155.04, -1.696],
                                        [155.04, -67.615],
                                        [187.043, -67.615],
                                        [187.043, -88.482],
                                        [155.04, -88.482],
                                        [155.04, -126.803],
                                        [149.199, -126.803],
                                        [127.169, -88.584],
                                        [127.169, -88.482],
                                        [127.146, -88.482],
                                        [127.146, -88.479],
                                        [127.146, -88.477],
                                        [126.524, -85.133],
                                        [126.014, -81.579],
                                        [125.793, -77.235],
                                        [125.793, -76.368],
                                        [125.806, -76.386],
                                        [125.761, 0.307],
                                        [134.309, 25.035],
                                        [158.582, 33.992],
                                        [175.814, 31.266],
                                        [187.664, 25.378],
                                        [184.748, 20.316],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 4",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: "sh",
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [1.409, -0.996],
                                        [0.589, -1.548],
                                        [0, 0],
                                        [0.913, -3.634],
                                        [0.686, -3.639],
                                        [0.908, 3.64],
                                        [1.321, 3.773],
                                        [0, 0],
                                        [1.456, 1.048],
                                        [2.137, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-1.998, 0],
                                        [-1.456, 1.048],
                                        [0, 0],
                                        [-1.409, 3.773],
                                        [-0.909, 3.64],
                                        [-0.723, -3.639],
                                        [-0.909, -3.634],
                                        [0, 0],
                                        [-0.547, -1.548],
                                        [-1.451, -0.996],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [388.626, -89.477],
                                        [365.528, -89.477],
                                        [360.439, -87.935],
                                        [357.439, -84.021],
                                        [333.387, -19.011],
                                        [329.887, -7.876],
                                        [327.476, 3.037],
                                        [325.024, -7.876],
                                        [321.658, -19.011],
                                        [298.158, -84.021],
                                        [295.154, -87.935],
                                        [289.745, -89.477],
                                        [265.423, -89.477],
                                        [313.837, 32.221],
                                        [340.387, 32.221],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 5",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: "sh",
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-4.817, 4.863],
                                        [-5.155, 1.016],
                                        [-2.601, 0],
                                        [-3.227, -1.497],
                                        [-2.137, -2.504],
                                        [-1.002, -3.366],
                                        [0, -3.815],
                                    ],
                                    o: [
                                        [1.321, -8.864],
                                        [3.352, -3.352],
                                        [2.258, -0.449],
                                        [4.362, 0],
                                        [3.272, 1.456],
                                        [2.137, 2.545],
                                        [1.043, 3.365],
                                        [0, 0],
                                    ],
                                    v: [
                                        [425.991, -42.609],
                                        [435.221, -63.154],
                                        [447.985, -69.711],
                                        [455.272, -70.383],
                                        [466.636, -68.158],
                                        [474.73, -62.2],
                                        [479.459, -53.336],
                                        [481.002, -42.609],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 6",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 6,
                            ty: "sh",
                            ix: 7,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.591, 6.866],
                                        [4.683, 4.683],
                                        [6.407, 2.411],
                                        [7.682, 0],
                                        [5.479, -1.553],
                                        [2.147, -0.932],
                                        [4.998, -5.411],
                                        [2.684, -7.32],
                                        [0, -8.322],
                                        [-3.092, -8.141],
                                        [-5.368, -5.503],
                                        [-7.274, -2.819],
                                        [-8.457, 0],
                                        [-4.548, 0.635],
                                        [-0.681, 0.115],
                                        [-3.746, 1.312],
                                        [-4.047, 2.592],
                                        [-1.447, 1.186],
                                        [0, 0],
                                        [3.987, -2.121],
                                        [0.487, -0.251],
                                        [3.407, -1.09],
                                        [4.728, 0],
                                        [5.865, 5.864],
                                        [0.862, 12.504],
                                        [0, 0],
                                        [-0.862, 0.32],
                                        [-0.496, 0.821],
                                        [-0.014, 0.028],
                                        [-0.052, 0.093],
                                        [0, 0],
                                        [-0.199, 1.275],
                                        [0, 2.183],
                                    ],
                                    o: [
                                        [-2.592, -6.82],
                                        [-4.682, -4.729],
                                        [-6.458, -2.453],
                                        [-6.393, 0],
                                        [-2.314, 0.658],
                                        [-7.227, 3.18],
                                        [-5.002, 5.41],
                                        [-2.684, 7.316],
                                        [0, 10.681],
                                        [3.093, 8.09],
                                        [5.364, 5.498],
                                        [7.274, 2.818],
                                        [4.274, 0],
                                        [0.686, -0.098],
                                        [3.839, -0.659],
                                        [4.367, -1.549],
                                        [1.563, -0.987],
                                        [0, 0],
                                        [-2.255, -3.912],
                                        [-0.658, 0.35],
                                        [-2.591, 1.364],
                                        [-3.459, 1.094],
                                        [-9.639, 0],
                                        [-5.818, -5.819],
                                        [0, 0],
                                        [1.364, 0],
                                        [0.881, -0.353],
                                        [0.018, -0.028],
                                        [0.051, -0.088],
                                        [0, 0],
                                        [0.404, -0.784],
                                        [0.181, -1.451],
                                        [0, -8.776],
                                    ],
                                    v: [
                                        [503.413, -59.654],
                                        [492.503, -76.929],
                                        [475.866, -87.661],
                                        [454.678, -91.342],
                                        [436.867, -89.001],
                                        [430.173, -86.613],
                                        [411.809, -73.748],
                                        [400.262, -54.653],
                                        [396.215, -31.24],
                                        [400.855, -3.054],
                                        [413.538, 17.312],
                                        [432.496, 29.769],
                                        [456.089, 33.996],
                                        [469.319, 33.042],
                                        [471.368, 32.722],
                                        [482.726, 29.769],
                                        [495.368, 23.584],
                                        [499.876, 20.316],
                                        [492.94, 8.283],
                                        [481.746, 5.072],
                                        [480.001, 5.99],
                                        [471.002, 9.672],
                                        [458.726, 11.308],
                                        [435.449, 2.537],
                                        [425.402, -24.969],
                                        [500.552, -24.969],
                                        [503.866, -25.469],
                                        [505.912, -27.208],
                                        [505.962, -27.287],
                                        [506.116, -27.555],
                                        [506.142, -27.611],
                                        [507.052, -30.699],
                                        [507.325, -36.197],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 7",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 7,
                            ty: "sh",
                            ix: 8,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-4.817, 4.863],
                                        [-5.155, 1.016],
                                        [-2.601, 0],
                                        [-3.226, -1.497],
                                        [-2.137, -2.504],
                                        [-1.002, -3.366],
                                        [0, -3.815],
                                    ],
                                    o: [
                                        [1.321, -8.864],
                                        [3.352, -3.352],
                                        [2.258, -0.449],
                                        [4.362, 0],
                                        [3.273, 1.456],
                                        [2.137, 2.545],
                                        [1.043, 3.365],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-88.13, -42.609],
                                        [-78.9, -63.154],
                                        [-66.138, -69.711],
                                        [-58.849, -70.383],
                                        [-47.488, -68.158],
                                        [-39.393, -62.2],
                                        [-34.664, -53.336],
                                        [-33.12, -42.609],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 8",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 8,
                            ty: "sh",
                            ix: 9,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [4.682, 4.683],
                                        [6.407, 2.411],
                                        [7.682, 0],
                                        [5.479, -1.553],
                                        [2.147, -0.932],
                                        [4.998, -5.411],
                                        [2.684, -7.32],
                                        [0, -8.322],
                                        [-3.092, -8.141],
                                        [-5.368, -5.503],
                                        [-7.274, -2.819],
                                        [-8.456, 0],
                                        [-4.548, 0.635],
                                        [-0.682, 0.115],
                                        [-3.746, 1.312],
                                        [-4.047, 2.592],
                                        [-1.446, 1.186],
                                        [0, 0],
                                        [3.987, -2.121],
                                        [0.487, -0.251],
                                        [3.408, -1.09],
                                        [4.729, 0],
                                        [5.865, 5.864],
                                        [0.862, 12.504],
                                        [0, 0],
                                        [-0.862, 0.32],
                                        [-0.496, 0.821],
                                        [-0.015, 0.028],
                                        [-0.052, 0.093],
                                        [0, 0],
                                        [-0.199, 1.275],
                                        [0, 2.183],
                                        [2.591, 6.866],
                                    ],
                                    o: [
                                        [-4.682, -4.729],
                                        [-6.458, -2.453],
                                        [-6.393, 0],
                                        [-2.314, 0.658],
                                        [-7.227, 3.18],
                                        [-5.002, 5.41],
                                        [-2.684, 7.316],
                                        [0, 10.681],
                                        [3.093, 8.09],
                                        [5.364, 5.498],
                                        [7.274, 2.818],
                                        [4.275, 0],
                                        [0.686, -0.098],
                                        [3.838, -0.659],
                                        [4.367, -1.549],
                                        [1.563, -0.987],
                                        [0, 0],
                                        [-2.255, -3.912],
                                        [-0.658, 0.35],
                                        [-2.592, 1.364],
                                        [-3.458, 1.094],
                                        [-9.638, 0],
                                        [-5.818, -5.819],
                                        [0, 0],
                                        [1.363, 0],
                                        [0.881, -0.353],
                                        [0.019, -0.028],
                                        [0.05, -0.088],
                                        [0, 0],
                                        [0.404, -0.784],
                                        [0.181, -1.451],
                                        [0, -8.776],
                                        [-2.592, -6.82],
                                    ],
                                    v: [
                                        [-21.618, -76.929],
                                        [-38.257, -87.661],
                                        [-59.443, -91.342],
                                        [-77.254, -89.001],
                                        [-83.95, -86.613],
                                        [-102.312, -73.748],
                                        [-113.861, -54.653],
                                        [-117.908, -31.24],
                                        [-113.268, -3.054],
                                        [-100.583, 17.312],
                                        [-81.627, 29.769],
                                        [-58.035, 33.996],
                                        [-44.802, 33.042],
                                        [-42.754, 32.722],
                                        [-31.395, 29.769],
                                        [-18.754, 23.584],
                                        [-14.248, 20.316],
                                        [-21.182, 8.283],
                                        [-32.375, 5.072],
                                        [-34.122, 5.99],
                                        [-43.12, 9.672],
                                        [-55.396, 11.308],
                                        [-78.674, 2.537],
                                        [-88.719, -24.969],
                                        [-13.571, -24.969],
                                        [-10.255, -25.469],
                                        [-8.212, -27.208],
                                        [-8.16, -27.287],
                                        [-8.007, -27.555],
                                        [-7.979, -27.611],
                                        [-7.071, -30.699],
                                        [-6.798, -36.197],
                                        [-10.71, -59.654],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 9",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 9,
                            ty: "sh",
                            ix: 10,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.772, 2.503],
                                        [3.546, 1.591],
                                        [3.685, 1.228],
                                        [3.547, 1.043],
                                        [2.819, 1.368],
                                        [1.725, 1.776],
                                        [0, 2.634],
                                        [-3.315, 2.457],
                                        [-5.772, 0],
                                        [-2.726, -0.774],
                                        [-2.138, -1.001],
                                        [-1.59, -0.816],
                                        [-1.409, 0],
                                        [-0.862, 0.501],
                                        [-0.821, 1.271],
                                        [0, 0],
                                        [6.866, 2.777],
                                        [8.368, 0],
                                        [5.725, -1.998],
                                        [3.82, -3.366],
                                        [1.956, -4.501],
                                        [0, -4.956],
                                        [-1.729, -3.82],
                                        [-2.819, -2.591],
                                        [-3.588, -1.682],
                                        [-3.727, -1.275],
                                        [-3.588, -1.094],
                                        [-2.819, -1.317],
                                        [-1.725, -1.817],
                                        [0, -2.865],
                                        [0.774, -1.775],
                                        [1.632, -1.363],
                                        [2.499, -0.863],
                                        [3.5, 0],
                                        [2.907, 1.048],
                                        [2.137, 1.182],
                                        [1.595, 1.043],
                                        [1.817, 0],
                                        [1.182, -0.723],
                                        [0.867, -1.317],
                                        [0, 0],
                                        [-3.138, -1.822],
                                        [-3.681, -1.316],
                                        [-4.001, -0.728],
                                        [-4.182, 0],
                                        [-6.045, 2.003],
                                        [-4.135, 3.593],
                                        [-2.142, 4.951],
                                        [0, 5.864],
                                        [1.724, 3.5],
                                    ],
                                    o: [
                                        [-2.815, -2.452],
                                        [-3.547, -1.636],
                                        [-3.723, -1.275],
                                        [-3.546, -1.09],
                                        [-2.819, -1.316],
                                        [-1.682, -1.771],
                                        [0, -3.959],
                                        [3.319, -2.453],
                                        [3.681, 0],
                                        [2.726, 0.821],
                                        [2.09, 0.997],
                                        [1.637, 0.774],
                                        [1.321, 0],
                                        [0.863, -0.543],
                                        [0, 0],
                                        [-4.59, -4.501],
                                        [-6.907, -2.772],
                                        [-7.501, 0],
                                        [-5.73, 2.05],
                                        [-3.862, 3.361],
                                        [-1.998, 4.497],
                                        [0, 5.364],
                                        [1.683, 3.773],
                                        [2.772, 2.592],
                                        [3.593, 1.683],
                                        [3.774, 1.27],
                                        [3.593, 1.043],
                                        [2.818, 1.368],
                                        [1.683, 1.817],
                                        [0, 1.864],
                                        [-0.728, 1.771],
                                        [-1.636, 1.363],
                                        [-2.504, 0.816],
                                        [-4.408, 0],
                                        [-2.865, -1.001],
                                        [-2.137, -1.183],
                                        [-1.631, -1.048],
                                        [-1.817, 0],
                                        [-1.183, 0.686],
                                        [0, 0],
                                        [2.365, 2.137],
                                        [3.092, 1.817],
                                        [3.685, 1.368],
                                        [4.001, 0.774],
                                        [7.821, 0],
                                        [6.05, -2.044],
                                        [4.094, -3.639],
                                        [2.132, -4.956],
                                        [0, -4.914],
                                        [-1.683, -3.454],
                                    ],
                                    v: [
                                        [-144.866, -27.88],
                                        [-154.411, -34.014],
                                        [-165.325, -38.334],
                                        [-176.233, -41.834],
                                        [-185.779, -45.474],
                                        [-192.552, -50.156],
                                        [-195.098, -56.744],
                                        [-190.099, -66.339],
                                        [-176.461, -70.021],
                                        [-166.822, -68.839],
                                        [-159.502, -66.154],
                                        [-153.554, -63.126],
                                        [-148.963, -61.943],
                                        [-145.645, -62.718],
                                        [-143.14, -65.398],
                                        [-136.864, -76.294],
                                        [-154.051, -87.206],
                                        [-176.962, -91.342],
                                        [-196.827, -88.343],
                                        [-211.146, -80.248],
                                        [-219.876, -68.473],
                                        [-222.829, -54.245],
                                        [-220.284, -40.471],
                                        [-213.512, -30.88],
                                        [-203.919, -24.422],
                                        [-192.918, -19.966],
                                        [-181.87, -16.466],
                                        [-172.279, -12.878],
                                        [-165.505, -8.102],
                                        [-162.961, -1.102],
                                        [-164.097, 4.4],
                                        [-167.639, 9.083],
                                        [-173.822, 12.356],
                                        [-182.78, 13.584],
                                        [-193.734, 12.036],
                                        [-201.281, 8.717],
                                        [-207.12, 5.264],
                                        [-212.298, 3.716],
                                        [-216.799, 4.805],
                                        [-219.892, 7.851],
                                        [-226.376, 19.175],
                                        [-218.147, 25.086],
                                        [-207.966, 29.81],
                                        [-196.465, 32.949],
                                        [-184.189, 34.085],
                                        [-163.368, 31.085],
                                        [-148.139, 22.675],
                                        [-138.77, 9.857],
                                        [-135.59, -6.326],
                                        [-138.134, -18.923],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 10",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 10,
                            ty: "sh",
                            ix: 11,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.772, 2.503],
                                        [3.546, 1.591],
                                        [3.685, 1.228],
                                        [3.547, 1.043],
                                        [2.819, 1.368],
                                        [1.724, 1.776],
                                        [0, 2.634],
                                        [-3.315, 2.457],
                                        [-5.772, 0],
                                        [-2.726, -0.774],
                                        [-2.138, -1.001],
                                        [-1.59, -0.816],
                                        [-1.409, 0],
                                        [-0.863, 0.501],
                                        [-0.821, 1.271],
                                        [0, 0],
                                        [6.866, 2.777],
                                        [8.368, 0],
                                        [5.726, -1.998],
                                        [3.82, -3.366],
                                        [1.957, -4.501],
                                        [0, -4.956],
                                        [-1.729, -3.82],
                                        [-2.818, -2.591],
                                        [-3.588, -1.682],
                                        [-3.727, -1.275],
                                        [-3.588, -1.094],
                                        [-2.819, -1.317],
                                        [-1.725, -1.817],
                                        [0, -2.865],
                                        [0.775, -1.775],
                                        [1.632, -1.363],
                                        [2.499, -0.863],
                                        [3.5, 0],
                                        [2.907, 1.048],
                                        [2.137, 1.182],
                                        [1.594, 1.043],
                                        [1.817, 0],
                                        [1.182, -0.723],
                                        [0.867, -1.317],
                                        [0, 0],
                                        [-3.138, -1.822],
                                        [-3.681, -1.316],
                                        [-4.001, -0.728],
                                        [-4.182, 0],
                                        [-6.045, 2.003],
                                        [-4.136, 3.593],
                                        [-2.142, 4.951],
                                        [0, 5.864],
                                        [1.724, 3.5],
                                    ],
                                    o: [
                                        [-2.815, -2.452],
                                        [-3.547, -1.636],
                                        [-3.723, -1.275],
                                        [-3.546, -1.09],
                                        [-2.819, -1.316],
                                        [-1.683, -1.771],
                                        [0, -3.959],
                                        [3.319, -2.453],
                                        [3.681, 0],
                                        [2.726, 0.821],
                                        [2.091, 0.997],
                                        [1.637, 0.774],
                                        [1.321, 0],
                                        [0.862, -0.543],
                                        [0, 0],
                                        [-4.589, -4.501],
                                        [-6.908, -2.772],
                                        [-7.501, 0],
                                        [-5.73, 2.05],
                                        [-3.862, 3.361],
                                        [-1.998, 4.497],
                                        [0, 5.364],
                                        [1.683, 3.773],
                                        [2.773, 2.592],
                                        [3.593, 1.683],
                                        [3.774, 1.27],
                                        [3.593, 1.043],
                                        [2.818, 1.368],
                                        [1.683, 1.817],
                                        [0, 1.864],
                                        [-0.728, 1.771],
                                        [-1.636, 1.363],
                                        [-2.503, 0.816],
                                        [-4.408, 0],
                                        [-2.865, -1.001],
                                        [-2.137, -1.183],
                                        [-1.632, -1.048],
                                        [-1.817, 0],
                                        [-1.183, 0.686],
                                        [0, 0],
                                        [2.365, 2.137],
                                        [3.092, 1.817],
                                        [3.686, 1.368],
                                        [4.001, 0.774],
                                        [7.821, 0],
                                        [6.05, -2.044],
                                        [4.093, -3.639],
                                        [2.132, -4.956],
                                        [0, -4.914],
                                        [-1.683, -3.454],
                                    ],
                                    v: [
                                        [-250.861, -27.88],
                                        [-260.406, -34.014],
                                        [-271.319, -38.334],
                                        [-282.228, -41.834],
                                        [-291.773, -45.474],
                                        [-298.546, -50.156],
                                        [-301.091, -56.744],
                                        [-296.094, -66.339],
                                        [-282.455, -70.021],
                                        [-272.817, -68.839],
                                        [-265.496, -66.154],
                                        [-259.548, -63.126],
                                        [-254.958, -61.943],
                                        [-251.638, -62.718],
                                        [-249.135, -65.398],
                                        [-242.859, -76.294],
                                        [-260.044, -87.206],
                                        [-282.956, -91.342],
                                        [-302.821, -88.343],
                                        [-317.141, -80.248],
                                        [-325.871, -68.473],
                                        [-328.824, -54.245],
                                        [-326.279, -40.471],
                                        [-319.506, -30.88],
                                        [-309.914, -24.422],
                                        [-298.913, -19.966],
                                        [-287.865, -16.466],
                                        [-278.273, -12.878],
                                        [-271.5, -8.102],
                                        [-268.955, -1.102],
                                        [-270.091, 4.4],
                                        [-273.633, 9.083],
                                        [-279.817, 12.356],
                                        [-288.774, 13.584],
                                        [-299.729, 12.036],
                                        [-307.276, 8.717],
                                        [-313.114, 5.264],
                                        [-318.293, 3.716],
                                        [-322.794, 4.805],
                                        [-325.887, 7.851],
                                        [-332.371, 19.175],
                                        [-324.142, 25.086],
                                        [-313.961, 29.81],
                                        [-302.459, 32.949],
                                        [-290.183, 34.085],
                                        [-269.363, 31.085],
                                        [-254.133, 22.675],
                                        [-244.764, 9.857],
                                        [-241.585, -6.326],
                                        [-244.129, -18.923],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 11",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 11,
                            ty: "sh",
                            ix: 12,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.917, -0.701],
                                        [0.387, -0.676],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.918, 0.797],
                                        [0.073, 0.048],
                                        [1.328, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-1.45, 0.025],
                                        [-0.653, 0.531],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.46, -0.918],
                                        [-0.072, -0.048],
                                        [-0.893, -0.701],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [275.018, 66.28],
                                        [265.185, 66.28],
                                        [261.634, 67.392],
                                        [260.039, 69.179],
                                        [244.19, 96.624],
                                        [230.928, 73.649],
                                        [230.903, 73.601],
                                        [219.597, 52.824],
                                        [217.519, 50.239],
                                        [217.325, 50.094],
                                        [213.992, 49.055],
                                        [196.186, 49.055],
                                        [233.923, 114.405],
                                        [219.042, 140.183],
                                        [234.43, 140.183],
                                        [235.686, 140.183],
                                        [238.136, 135.942],
                                        [242.258, 128.804],
                                        [277.507, 67.754],
                                        [278.352, 66.28],
                                        [276.66, 66.28],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 12",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 12,
                            ty: "sh",
                            ix: 13,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [1.091, -3.221],
                                        [2.012, -2.231],
                                        [2.88, -1.193],
                                        [3.612, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-2.882, -1.194],
                                        [-2.011, -2.232],
                                        [-1.091, -3.221],
                                        [0, -4.057],
                                    ],
                                    o: [
                                        [-1.091, 3.221],
                                        [-2.011, 2.234],
                                        [-2.882, 1.194],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [3.612, 0],
                                        [2.88, 1.192],
                                        [2.012, 2.233],
                                        [1.091, 3.221],
                                        [0, 4.056],
                                    ],
                                    v: [
                                        [486.155, 114.142],
                                        [481.501, 122.323],
                                        [474.167, 127.462],
                                        [464.426, 129.251],
                                        [450.058, 129.251],
                                        [450.058, 77.202],
                                        [464.426, 77.202],
                                        [474.167, 78.993],
                                        [481.501, 84.13],
                                        [486.155, 92.311],
                                        [487.79, 103.228],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 13",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 13,
                            ty: "sh",
                            ix: 14,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [3.273, 3.256],
                                        [4.601, 1.824],
                                        [5.589, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-4.601, 1.806],
                                        [-3.271, 3.271],
                                        [-1.806, 4.535],
                                        [0, 5.418],
                                        [1.807, 4.533],
                                    ],
                                    o: [
                                        [-3.271, -3.256],
                                        [-4.601, -1.823],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [5.589, 0],
                                        [4.601, -1.806],
                                        [3.273, -3.274],
                                        [1.807, -4.533],
                                        [0, -5.421],
                                        [-1.806, -4.533],
                                    ],
                                    v: [
                                        [491.523, 76.615],
                                        [479.714, 68.996],
                                        [464.426, 66.26],
                                        [436.202, 66.26],
                                        [436.202, 140.193],
                                        [464.426, 140.193],
                                        [479.714, 137.484],
                                        [491.523, 129.866],
                                        [499.142, 118.156],
                                        [501.851, 103.228],
                                        [499.142, 88.297],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 14",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 14,
                            ty: "sh",
                            ix: 15,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [395.861, 66.26],
                                        [382.106, 66.26],
                                        [382.106, 140.193],
                                        [425.465, 140.193],
                                        [425.465, 128.842],
                                        [395.861, 128.842],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 15",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 15,
                            ty: "sh",
                            ix: 16,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [322.339, 140.193],
                                        [368.967, 140.193],
                                        [368.967, 129.201],
                                        [336.194, 129.201],
                                        [336.194, 108.29],
                                        [362.013, 108.29],
                                        [362.013, 97.704],
                                        [336.194, 97.704],
                                        [336.194, 77.202],
                                        [368.967, 77.202],
                                        [368.967, 66.26],
                                        [322.339, 66.26],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 16",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 16,
                            ty: "sh",
                            ix: 17,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [290.689, 140.193],
                                        [304.496, 140.193],
                                        [304.496, 66.26],
                                        [290.689, 66.26],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 17",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        { ty: "mm", mm: 1, nm: "Merge Paths 1", mn: "ADBE Vector Filter - Merge", hd: false },
                        {
                            ty: "fl",
                            c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [507.575, 140.444], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Group 1",
                    np: 19,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 0,
            op: 167,
            st: 0,
            ct: 1,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: "AY",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [79.142, 176.122, 0], ix: 2, l: 2 },
                a: { a: 0, k: [79.116, 182.168, 0], ix: 1, l: 2 },
                s: { a: 0, k: [176.3, 176.3, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [6.544, -33.122],
                                        [-31.701, 33.122],
                                        [18.612, 33.122],
                                        [31.701, 10.452],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [79.116, 182.168], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "SHAPE LEFT",
                    np: 1,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.276, 36.166],
                                        [20.881, 7.405],
                                        [-4.276, -36.166],
                                        [-20.881, -7.407],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [140.841, 103.393], ix: 2 },
                            a: { a: 0, k: [26.6, -34], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "SHAPE UP",
                    np: 1,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [29.694, 25.715],
                                        [0, -25.715],
                                        [-29.694, 25.715],
                                        [-28.663, 25.715],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [204.768, 171.491], ix: 2 },
                            a: { a: 0, k: [54.4, -5], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "SHAPE RIGHT",
                    np: 1,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gf",
                    o: { a: 0, k: 100, ix: 10 },
                    r: 1,
                    bm: 0,
                    g: {
                        p: 3,
                        k: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 10,
                                    s: [0, 0.996, 0.059, 0.549, 0.5, 0.998, 0.429, 0.38, 1, 1, 0.8, 0.212],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 40,
                                    s: [0.047, 0.404, 0.024, 0.976, 0.524, 0.243, 0.406, 0.982, 1, 0.082, 0.788, 0.988],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 70,
                                    s: [0, 0.404, 0.024, 0.976, 0.5, 0.243, 0.406, 0.982, 1, 0.082, 0.788, 0.988],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 100,
                                    s: [0, 0.925, 0.176, 0.827, 0.5, 0.516, 0.565, 0.79, 1, 0.106, 0.953, 0.753],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 130,
                                    s: [0, 0.925, 0.176, 0.827, 0.5, 0.516, 0.565, 0.79, 1, 0.106, 0.953, 0.753],
                                },
                                { t: 160, s: [0, 0.996, 0.059, 0.549, 0.5, 0.998, 0.429, 0.38, 1, 1, 0.8, 0.212] },
                            ],
                            ix: 9,
                        },
                    },
                    s: { a: 0, k: [19, 41], ix: 5 },
                    e: { a: 0, k: [-27, 92], ix: 6 },
                    t: 1,
                    nm: "Gradient Fill 1",
                    mn: "ADBE Vector Graphic - G-Fill",
                    hd: false,
                },
            ],
            ip: 0,
            op: 167,
            st: 0,
            ct: 1,
            bm: 0,
        },
    ],
    markers: [],
    props: {},
};

const ANIM_DATA_LIGHT = {
    v: "5.12.1",
    fr: 30,
    ip: 0,
    op: 167,
    w: 800,
    h: 280,
    nm: "#AY_loading animation_full logo_st",
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "AY-type",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [526.448, 148.462, 0], ix: 2, l: 2 },
                a: { a: 0, k: [507.576, 140.444, 0], ix: 1, l: 2 },
                s: { a: 0, k: [49.758, 49.758, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [243.969, -61.749],
                                        [228.502, -88.581],
                                        [227.988, -89.477],
                                        [214.693, -89.477],
                                        [214.693, 32.13],
                                        [243.969, 32.176],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 1,
                            ty: "sh",
                            ix: 2,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-405.263, -138.193],
                                        [-406.446, -140.193],
                                        [-423.765, -110.188],
                                        [-507.325, 34.468],
                                        [-468.683, 34.468],
                                        [-404.444, -76.731],
                                        [-357.438, 4.737],
                                        [-340.117, -25.314],
                                        [-387.125, -106.734],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 2",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 2,
                            ty: "sh",
                            ix: 3,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [1.368, -2.37],
                                        [0, 0],
                                        [0, -0.088],
                                        [0.047, 0.041],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 3.5],
                                        [0, 0],
                                        [-4.219, 0],
                                        [0, 0],
                                        [-1.78, 3.078],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0.092],
                                        [0, -0.047],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [3.453, 0],
                                        [0, 0],
                                        [0, -4.219],
                                        [0, 0],
                                        [3.561, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-2.74, 0],
                                    ],
                                    v: [
                                        [56.948, -84.649],
                                        [48.886, -70.704],
                                        [48.84, -70.477],
                                        [48.793, -70.565],
                                        [38.431, -88.479],
                                        [19.428, -88.479],
                                        [19.428, 32.131],
                                        [42.387, 32.131],
                                        [48.704, 25.766],
                                        [48.704, -50.881],
                                        [56.34, -58.521],
                                        [104.095, -58.521],
                                        [112.719, -63.495],
                                        [118.819, -74.052],
                                        [118.819, -74.055],
                                        [120.15, -76.36],
                                        [120.15, -76.365],
                                        [120.164, -76.383],
                                        [127.146, -88.479],
                                        [63.586, -88.479],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 3",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 3,
                            ty: "sh",
                            ix: 4,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0.384, -0.241],
                                        [0.909, -0.501],
                                        [1.224, -0.455],
                                        [1.818, 0],
                                        [1.91, 2.138],
                                        [0, 3.82],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.121, -1.191],
                                        [0, -1.451],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-5.684, -5.953],
                                        [-10.5, 0],
                                        [-5.544, 1.817],
                                        [-3.575, 2.494],
                                        [0, 0],
                                    ],
                                    o: [
                                        [-0.371, 0.097],
                                        [-0.728, 0.408],
                                        [-0.909, 0.454],
                                        [-1.228, 0.412],
                                        [-3.18, 0],
                                        [-1.91, -2.137],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.222, 1.177],
                                        [-0.148, 1.442],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 10.547],
                                        [5.684, 6.004],
                                        [5.958, 0],
                                        [4.321, -1.433],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [176.806, 6.533],
                                        [175.68, 7.034],
                                        [173.269, 8.397],
                                        [170.043, 9.76],
                                        [165.495, 10.399],
                                        [157.902, 7.214],
                                        [155.04, -1.696],
                                        [155.04, -67.615],
                                        [187.043, -67.615],
                                        [187.043, -88.482],
                                        [155.04, -88.482],
                                        [155.04, -126.803],
                                        [149.199, -126.803],
                                        [127.169, -88.584],
                                        [127.169, -88.482],
                                        [127.146, -88.482],
                                        [127.146, -88.479],
                                        [127.146, -88.477],
                                        [126.524, -85.133],
                                        [126.014, -81.579],
                                        [125.793, -77.235],
                                        [125.793, -76.368],
                                        [125.806, -76.386],
                                        [125.761, 0.307],
                                        [134.309, 25.035],
                                        [158.582, 33.992],
                                        [175.814, 31.266],
                                        [187.664, 25.378],
                                        [184.748, 20.316],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 4",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 4,
                            ty: "sh",
                            ix: 5,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [1.409, -0.996],
                                        [0.589, -1.548],
                                        [0, 0],
                                        [0.913, -3.634],
                                        [0.686, -3.639],
                                        [0.908, 3.64],
                                        [1.321, 3.773],
                                        [0, 0],
                                        [1.456, 1.048],
                                        [2.137, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-1.998, 0],
                                        [-1.456, 1.048],
                                        [0, 0],
                                        [-1.409, 3.773],
                                        [-0.909, 3.64],
                                        [-0.723, -3.639],
                                        [-0.909, -3.634],
                                        [0, 0],
                                        [-0.547, -1.548],
                                        [-1.451, -0.996],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [388.626, -89.477],
                                        [365.528, -89.477],
                                        [360.439, -87.935],
                                        [357.439, -84.021],
                                        [333.387, -19.011],
                                        [329.887, -7.876],
                                        [327.476, 3.037],
                                        [325.024, -7.876],
                                        [321.658, -19.011],
                                        [298.158, -84.021],
                                        [295.154, -87.935],
                                        [289.745, -89.477],
                                        [265.423, -89.477],
                                        [313.837, 32.221],
                                        [340.387, 32.221],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 5",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 5,
                            ty: "sh",
                            ix: 6,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-4.817, 4.863],
                                        [-5.155, 1.016],
                                        [-2.601, 0],
                                        [-3.227, -1.497],
                                        [-2.137, -2.504],
                                        [-1.002, -3.366],
                                        [0, -3.815],
                                    ],
                                    o: [
                                        [1.321, -8.864],
                                        [3.352, -3.352],
                                        [2.258, -0.449],
                                        [4.362, 0],
                                        [3.272, 1.456],
                                        [2.137, 2.545],
                                        [1.043, 3.365],
                                        [0, 0],
                                    ],
                                    v: [
                                        [425.991, -42.609],
                                        [435.221, -63.154],
                                        [447.985, -69.711],
                                        [455.272, -70.383],
                                        [466.636, -68.158],
                                        [474.73, -62.2],
                                        [479.459, -53.336],
                                        [481.002, -42.609],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 6",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 6,
                            ty: "sh",
                            ix: 7,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.591, 6.866],
                                        [4.683, 4.683],
                                        [6.407, 2.411],
                                        [7.682, 0],
                                        [5.479, -1.553],
                                        [2.147, -0.932],
                                        [4.998, -5.411],
                                        [2.684, -7.32],
                                        [0, -8.322],
                                        [-3.092, -8.141],
                                        [-5.368, -5.503],
                                        [-7.274, -2.819],
                                        [-8.457, 0],
                                        [-4.548, 0.635],
                                        [-0.681, 0.115],
                                        [-3.746, 1.312],
                                        [-4.047, 2.592],
                                        [-1.447, 1.186],
                                        [0, 0],
                                        [3.987, -2.121],
                                        [0.487, -0.251],
                                        [3.407, -1.09],
                                        [4.728, 0],
                                        [5.865, 5.864],
                                        [0.862, 12.504],
                                        [0, 0],
                                        [-0.862, 0.32],
                                        [-0.496, 0.821],
                                        [-0.014, 0.028],
                                        [-0.052, 0.093],
                                        [0, 0],
                                        [-0.199, 1.275],
                                        [0, 2.183],
                                    ],
                                    o: [
                                        [-2.592, -6.82],
                                        [-4.682, -4.729],
                                        [-6.458, -2.453],
                                        [-6.393, 0],
                                        [-2.314, 0.658],
                                        [-7.227, 3.18],
                                        [-5.002, 5.41],
                                        [-2.684, 7.316],
                                        [0, 10.681],
                                        [3.093, 8.09],
                                        [5.364, 5.498],
                                        [7.274, 2.818],
                                        [4.274, 0],
                                        [0.686, -0.098],
                                        [3.839, -0.659],
                                        [4.367, -1.549],
                                        [1.563, -0.987],
                                        [0, 0],
                                        [-2.255, -3.912],
                                        [-0.658, 0.35],
                                        [-2.591, 1.364],
                                        [-3.459, 1.094],
                                        [-9.639, 0],
                                        [-5.818, -5.819],
                                        [0, 0],
                                        [1.364, 0],
                                        [0.881, -0.353],
                                        [0.018, -0.028],
                                        [0.051, -0.088],
                                        [0, 0],
                                        [0.404, -0.784],
                                        [0.181, -1.451],
                                        [0, -8.776],
                                    ],
                                    v: [
                                        [503.413, -59.654],
                                        [492.503, -76.929],
                                        [475.866, -87.661],
                                        [454.678, -91.342],
                                        [436.867, -89.001],
                                        [430.173, -86.613],
                                        [411.809, -73.748],
                                        [400.262, -54.653],
                                        [396.215, -31.24],
                                        [400.855, -3.054],
                                        [413.538, 17.312],
                                        [432.496, 29.769],
                                        [456.089, 33.996],
                                        [469.319, 33.042],
                                        [471.368, 32.722],
                                        [482.726, 29.769],
                                        [495.368, 23.584],
                                        [499.876, 20.316],
                                        [492.94, 8.283],
                                        [481.746, 5.072],
                                        [480.001, 5.99],
                                        [471.002, 9.672],
                                        [458.726, 11.308],
                                        [435.449, 2.537],
                                        [425.402, -24.969],
                                        [500.552, -24.969],
                                        [503.866, -25.469],
                                        [505.912, -27.208],
                                        [505.962, -27.287],
                                        [506.116, -27.555],
                                        [506.142, -27.611],
                                        [507.052, -30.699],
                                        [507.325, -36.197],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 7",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 7,
                            ty: "sh",
                            ix: 8,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [-4.817, 4.863],
                                        [-5.155, 1.016],
                                        [-2.601, 0],
                                        [-3.226, -1.497],
                                        [-2.137, -2.504],
                                        [-1.002, -3.366],
                                        [0, -3.815],
                                    ],
                                    o: [
                                        [1.321, -8.864],
                                        [3.352, -3.352],
                                        [2.258, -0.449],
                                        [4.362, 0],
                                        [3.273, 1.456],
                                        [2.137, 2.545],
                                        [1.043, 3.365],
                                        [0, 0],
                                    ],
                                    v: [
                                        [-88.13, -42.609],
                                        [-78.9, -63.154],
                                        [-66.138, -69.711],
                                        [-58.849, -70.383],
                                        [-47.488, -68.158],
                                        [-39.393, -62.2],
                                        [-34.664, -53.336],
                                        [-33.12, -42.609],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 8",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 8,
                            ty: "sh",
                            ix: 9,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [4.682, 4.683],
                                        [6.407, 2.411],
                                        [7.682, 0],
                                        [5.479, -1.553],
                                        [2.147, -0.932],
                                        [4.998, -5.411],
                                        [2.684, -7.32],
                                        [0, -8.322],
                                        [-3.092, -8.141],
                                        [-5.368, -5.503],
                                        [-7.274, -2.819],
                                        [-8.456, 0],
                                        [-4.548, 0.635],
                                        [-0.682, 0.115],
                                        [-3.746, 1.312],
                                        [-4.047, 2.592],
                                        [-1.446, 1.186],
                                        [0, 0],
                                        [3.987, -2.121],
                                        [0.487, -0.251],
                                        [3.408, -1.09],
                                        [4.729, 0],
                                        [5.865, 5.864],
                                        [0.862, 12.504],
                                        [0, 0],
                                        [-0.862, 0.32],
                                        [-0.496, 0.821],
                                        [-0.015, 0.028],
                                        [-0.052, 0.093],
                                        [0, 0],
                                        [-0.199, 1.275],
                                        [0, 2.183],
                                        [2.591, 6.866],
                                    ],
                                    o: [
                                        [-4.682, -4.729],
                                        [-6.458, -2.453],
                                        [-6.393, 0],
                                        [-2.314, 0.658],
                                        [-7.227, 3.18],
                                        [-5.002, 5.41],
                                        [-2.684, 7.316],
                                        [0, 10.681],
                                        [3.093, 8.09],
                                        [5.364, 5.498],
                                        [7.274, 2.818],
                                        [4.275, 0],
                                        [0.686, -0.098],
                                        [3.838, -0.659],
                                        [4.367, -1.549],
                                        [1.563, -0.987],
                                        [0, 0],
                                        [-2.255, -3.912],
                                        [-0.658, 0.35],
                                        [-2.592, 1.364],
                                        [-3.458, 1.094],
                                        [-9.638, 0],
                                        [-5.818, -5.819],
                                        [0, 0],
                                        [1.363, 0],
                                        [0.881, -0.353],
                                        [0.019, -0.028],
                                        [0.05, -0.088],
                                        [0, 0],
                                        [0.404, -0.784],
                                        [0.181, -1.451],
                                        [0, -8.776],
                                        [-2.592, -6.82],
                                    ],
                                    v: [
                                        [-21.618, -76.929],
                                        [-38.257, -87.661],
                                        [-59.443, -91.342],
                                        [-77.254, -89.001],
                                        [-83.95, -86.613],
                                        [-102.312, -73.748],
                                        [-113.861, -54.653],
                                        [-117.908, -31.24],
                                        [-113.268, -3.054],
                                        [-100.583, 17.312],
                                        [-81.627, 29.769],
                                        [-58.035, 33.996],
                                        [-44.802, 33.042],
                                        [-42.754, 32.722],
                                        [-31.395, 29.769],
                                        [-18.754, 23.584],
                                        [-14.248, 20.316],
                                        [-21.182, 8.283],
                                        [-32.375, 5.072],
                                        [-34.122, 5.99],
                                        [-43.12, 9.672],
                                        [-55.396, 11.308],
                                        [-78.674, 2.537],
                                        [-88.719, -24.969],
                                        [-13.571, -24.969],
                                        [-10.255, -25.469],
                                        [-8.212, -27.208],
                                        [-8.16, -27.287],
                                        [-8.007, -27.555],
                                        [-7.979, -27.611],
                                        [-7.071, -30.699],
                                        [-6.798, -36.197],
                                        [-10.71, -59.654],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 9",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 9,
                            ty: "sh",
                            ix: 10,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.772, 2.503],
                                        [3.546, 1.591],
                                        [3.685, 1.228],
                                        [3.547, 1.043],
                                        [2.819, 1.368],
                                        [1.725, 1.776],
                                        [0, 2.634],
                                        [-3.315, 2.457],
                                        [-5.772, 0],
                                        [-2.726, -0.774],
                                        [-2.138, -1.001],
                                        [-1.59, -0.816],
                                        [-1.409, 0],
                                        [-0.862, 0.501],
                                        [-0.821, 1.271],
                                        [0, 0],
                                        [6.866, 2.777],
                                        [8.368, 0],
                                        [5.725, -1.998],
                                        [3.82, -3.366],
                                        [1.956, -4.501],
                                        [0, -4.956],
                                        [-1.729, -3.82],
                                        [-2.819, -2.591],
                                        [-3.588, -1.682],
                                        [-3.727, -1.275],
                                        [-3.588, -1.094],
                                        [-2.819, -1.317],
                                        [-1.725, -1.817],
                                        [0, -2.865],
                                        [0.774, -1.775],
                                        [1.632, -1.363],
                                        [2.499, -0.863],
                                        [3.5, 0],
                                        [2.907, 1.048],
                                        [2.137, 1.182],
                                        [1.595, 1.043],
                                        [1.817, 0],
                                        [1.182, -0.723],
                                        [0.867, -1.317],
                                        [0, 0],
                                        [-3.138, -1.822],
                                        [-3.681, -1.316],
                                        [-4.001, -0.728],
                                        [-4.182, 0],
                                        [-6.045, 2.003],
                                        [-4.135, 3.593],
                                        [-2.142, 4.951],
                                        [0, 5.864],
                                        [1.724, 3.5],
                                    ],
                                    o: [
                                        [-2.815, -2.452],
                                        [-3.547, -1.636],
                                        [-3.723, -1.275],
                                        [-3.546, -1.09],
                                        [-2.819, -1.316],
                                        [-1.682, -1.771],
                                        [0, -3.959],
                                        [3.319, -2.453],
                                        [3.681, 0],
                                        [2.726, 0.821],
                                        [2.09, 0.997],
                                        [1.637, 0.774],
                                        [1.321, 0],
                                        [0.863, -0.543],
                                        [0, 0],
                                        [-4.59, -4.501],
                                        [-6.907, -2.772],
                                        [-7.501, 0],
                                        [-5.73, 2.05],
                                        [-3.862, 3.361],
                                        [-1.998, 4.497],
                                        [0, 5.364],
                                        [1.683, 3.773],
                                        [2.772, 2.592],
                                        [3.593, 1.683],
                                        [3.774, 1.27],
                                        [3.593, 1.043],
                                        [2.818, 1.368],
                                        [1.683, 1.817],
                                        [0, 1.864],
                                        [-0.728, 1.771],
                                        [-1.636, 1.363],
                                        [-2.504, 0.816],
                                        [-4.408, 0],
                                        [-2.865, -1.001],
                                        [-2.137, -1.183],
                                        [-1.631, -1.048],
                                        [-1.817, 0],
                                        [-1.183, 0.686],
                                        [0, 0],
                                        [2.365, 2.137],
                                        [3.092, 1.817],
                                        [3.685, 1.368],
                                        [4.001, 0.774],
                                        [7.821, 0],
                                        [6.05, -2.044],
                                        [4.094, -3.639],
                                        [2.132, -4.956],
                                        [0, -4.914],
                                        [-1.683, -3.454],
                                    ],
                                    v: [
                                        [-144.866, -27.88],
                                        [-154.411, -34.014],
                                        [-165.325, -38.334],
                                        [-176.233, -41.834],
                                        [-185.779, -45.474],
                                        [-192.552, -50.156],
                                        [-195.098, -56.744],
                                        [-190.099, -66.339],
                                        [-176.461, -70.021],
                                        [-166.822, -68.839],
                                        [-159.502, -66.154],
                                        [-153.554, -63.126],
                                        [-148.963, -61.943],
                                        [-145.645, -62.718],
                                        [-143.14, -65.398],
                                        [-136.864, -76.294],
                                        [-154.051, -87.206],
                                        [-176.962, -91.342],
                                        [-196.827, -88.343],
                                        [-211.146, -80.248],
                                        [-219.876, -68.473],
                                        [-222.829, -54.245],
                                        [-220.284, -40.471],
                                        [-213.512, -30.88],
                                        [-203.919, -24.422],
                                        [-192.918, -19.966],
                                        [-181.87, -16.466],
                                        [-172.279, -12.878],
                                        [-165.505, -8.102],
                                        [-162.961, -1.102],
                                        [-164.097, 4.4],
                                        [-167.639, 9.083],
                                        [-173.822, 12.356],
                                        [-182.78, 13.584],
                                        [-193.734, 12.036],
                                        [-201.281, 8.717],
                                        [-207.12, 5.264],
                                        [-212.298, 3.716],
                                        [-216.799, 4.805],
                                        [-219.892, 7.851],
                                        [-226.376, 19.175],
                                        [-218.147, 25.086],
                                        [-207.966, 29.81],
                                        [-196.465, 32.949],
                                        [-184.189, 34.085],
                                        [-163.368, 31.085],
                                        [-148.139, 22.675],
                                        [-138.77, 9.857],
                                        [-135.59, -6.326],
                                        [-138.134, -18.923],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 10",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 10,
                            ty: "sh",
                            ix: 11,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [2.772, 2.503],
                                        [3.546, 1.591],
                                        [3.685, 1.228],
                                        [3.547, 1.043],
                                        [2.819, 1.368],
                                        [1.724, 1.776],
                                        [0, 2.634],
                                        [-3.315, 2.457],
                                        [-5.772, 0],
                                        [-2.726, -0.774],
                                        [-2.138, -1.001],
                                        [-1.59, -0.816],
                                        [-1.409, 0],
                                        [-0.863, 0.501],
                                        [-0.821, 1.271],
                                        [0, 0],
                                        [6.866, 2.777],
                                        [8.368, 0],
                                        [5.726, -1.998],
                                        [3.82, -3.366],
                                        [1.957, -4.501],
                                        [0, -4.956],
                                        [-1.729, -3.82],
                                        [-2.818, -2.591],
                                        [-3.588, -1.682],
                                        [-3.727, -1.275],
                                        [-3.588, -1.094],
                                        [-2.819, -1.317],
                                        [-1.725, -1.817],
                                        [0, -2.865],
                                        [0.775, -1.775],
                                        [1.632, -1.363],
                                        [2.499, -0.863],
                                        [3.5, 0],
                                        [2.907, 1.048],
                                        [2.137, 1.182],
                                        [1.594, 1.043],
                                        [1.817, 0],
                                        [1.182, -0.723],
                                        [0.867, -1.317],
                                        [0, 0],
                                        [-3.138, -1.822],
                                        [-3.681, -1.316],
                                        [-4.001, -0.728],
                                        [-4.182, 0],
                                        [-6.045, 2.003],
                                        [-4.136, 3.593],
                                        [-2.142, 4.951],
                                        [0, 5.864],
                                        [1.724, 3.5],
                                    ],
                                    o: [
                                        [-2.815, -2.452],
                                        [-3.547, -1.636],
                                        [-3.723, -1.275],
                                        [-3.546, -1.09],
                                        [-2.819, -1.316],
                                        [-1.683, -1.771],
                                        [0, -3.959],
                                        [3.319, -2.453],
                                        [3.681, 0],
                                        [2.726, 0.821],
                                        [2.091, 0.997],
                                        [1.637, 0.774],
                                        [1.321, 0],
                                        [0.862, -0.543],
                                        [0, 0],
                                        [-4.589, -4.501],
                                        [-6.908, -2.772],
                                        [-7.501, 0],
                                        [-5.73, 2.05],
                                        [-3.862, 3.361],
                                        [-1.998, 4.497],
                                        [0, 5.364],
                                        [1.683, 3.773],
                                        [2.773, 2.592],
                                        [3.593, 1.683],
                                        [3.774, 1.27],
                                        [3.593, 1.043],
                                        [2.818, 1.368],
                                        [1.683, 1.817],
                                        [0, 1.864],
                                        [-0.728, 1.771],
                                        [-1.636, 1.363],
                                        [-2.503, 0.816],
                                        [-4.408, 0],
                                        [-2.865, -1.001],
                                        [-2.137, -1.183],
                                        [-1.632, -1.048],
                                        [-1.817, 0],
                                        [-1.183, 0.686],
                                        [0, 0],
                                        [2.365, 2.137],
                                        [3.092, 1.817],
                                        [3.686, 1.368],
                                        [4.001, 0.774],
                                        [7.821, 0],
                                        [6.05, -2.044],
                                        [4.093, -3.639],
                                        [2.132, -4.956],
                                        [0, -4.914],
                                        [-1.683, -3.454],
                                    ],
                                    v: [
                                        [-250.861, -27.88],
                                        [-260.406, -34.014],
                                        [-271.319, -38.334],
                                        [-282.228, -41.834],
                                        [-291.773, -45.474],
                                        [-298.546, -50.156],
                                        [-301.091, -56.744],
                                        [-296.094, -66.339],
                                        [-282.455, -70.021],
                                        [-272.817, -68.839],
                                        [-265.496, -66.154],
                                        [-259.548, -63.126],
                                        [-254.958, -61.943],
                                        [-251.638, -62.718],
                                        [-249.135, -65.398],
                                        [-242.859, -76.294],
                                        [-260.044, -87.206],
                                        [-282.956, -91.342],
                                        [-302.821, -88.343],
                                        [-317.141, -80.248],
                                        [-325.871, -68.473],
                                        [-328.824, -54.245],
                                        [-326.279, -40.471],
                                        [-319.506, -30.88],
                                        [-309.914, -24.422],
                                        [-298.913, -19.966],
                                        [-287.865, -16.466],
                                        [-278.273, -12.878],
                                        [-271.5, -8.102],
                                        [-268.955, -1.102],
                                        [-270.091, 4.4],
                                        [-273.633, 9.083],
                                        [-279.817, 12.356],
                                        [-288.774, 13.584],
                                        [-299.729, 12.036],
                                        [-307.276, 8.717],
                                        [-313.114, 5.264],
                                        [-318.293, 3.716],
                                        [-322.794, 4.805],
                                        [-325.887, 7.851],
                                        [-332.371, 19.175],
                                        [-324.142, 25.086],
                                        [-313.961, 29.81],
                                        [-302.459, 32.949],
                                        [-290.183, 34.085],
                                        [-269.363, 31.085],
                                        [-254.133, 22.675],
                                        [-244.764, 9.857],
                                        [-241.585, -6.326],
                                        [-244.129, -18.923],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 11",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 11,
                            ty: "sh",
                            ix: 12,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0.917, -0.701],
                                        [0.387, -0.676],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0.918, 0.797],
                                        [0.073, 0.048],
                                        [1.328, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-1.45, 0.025],
                                        [-0.653, 0.531],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.46, -0.918],
                                        [-0.072, -0.048],
                                        [-0.893, -0.701],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [275.018, 66.28],
                                        [265.185, 66.28],
                                        [261.634, 67.392],
                                        [260.039, 69.179],
                                        [244.19, 96.624],
                                        [230.928, 73.649],
                                        [230.903, 73.601],
                                        [219.597, 52.824],
                                        [217.519, 50.239],
                                        [217.325, 50.094],
                                        [213.992, 49.055],
                                        [196.186, 49.055],
                                        [233.923, 114.405],
                                        [219.042, 140.183],
                                        [234.43, 140.183],
                                        [235.686, 140.183],
                                        [238.136, 135.942],
                                        [242.258, 128.804],
                                        [277.507, 67.754],
                                        [278.352, 66.28],
                                        [276.66, 66.28],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 12",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 12,
                            ty: "sh",
                            ix: 13,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [1.091, -3.221],
                                        [2.012, -2.231],
                                        [2.88, -1.193],
                                        [3.612, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-2.882, -1.194],
                                        [-2.011, -2.232],
                                        [-1.091, -3.221],
                                        [0, -4.057],
                                    ],
                                    o: [
                                        [-1.091, 3.221],
                                        [-2.011, 2.234],
                                        [-2.882, 1.194],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [3.612, 0],
                                        [2.88, 1.192],
                                        [2.012, 2.233],
                                        [1.091, 3.221],
                                        [0, 4.056],
                                    ],
                                    v: [
                                        [486.155, 114.142],
                                        [481.501, 122.323],
                                        [474.167, 127.462],
                                        [464.426, 129.251],
                                        [450.058, 129.251],
                                        [450.058, 77.202],
                                        [464.426, 77.202],
                                        [474.167, 78.993],
                                        [481.501, 84.13],
                                        [486.155, 92.311],
                                        [487.79, 103.228],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 13",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 13,
                            ty: "sh",
                            ix: 14,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [3.273, 3.256],
                                        [4.601, 1.824],
                                        [5.589, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-4.601, 1.806],
                                        [-3.271, 3.271],
                                        [-1.806, 4.535],
                                        [0, 5.418],
                                        [1.807, 4.533],
                                    ],
                                    o: [
                                        [-3.271, -3.256],
                                        [-4.601, -1.823],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [5.589, 0],
                                        [4.601, -1.806],
                                        [3.273, -3.274],
                                        [1.807, -4.533],
                                        [0, -5.421],
                                        [-1.806, -4.533],
                                    ],
                                    v: [
                                        [491.523, 76.615],
                                        [479.714, 68.996],
                                        [464.426, 66.26],
                                        [436.202, 66.26],
                                        [436.202, 140.193],
                                        [464.426, 140.193],
                                        [479.714, 137.484],
                                        [491.523, 129.866],
                                        [499.142, 118.156],
                                        [501.851, 103.228],
                                        [499.142, 88.297],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 14",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 14,
                            ty: "sh",
                            ix: 15,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [395.861, 66.26],
                                        [382.106, 66.26],
                                        [382.106, 140.193],
                                        [425.465, 140.193],
                                        [425.465, 128.842],
                                        [395.861, 128.842],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 15",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 15,
                            ty: "sh",
                            ix: 16,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [322.339, 140.193],
                                        [368.967, 140.193],
                                        [368.967, 129.201],
                                        [336.194, 129.201],
                                        [336.194, 108.29],
                                        [362.013, 108.29],
                                        [362.013, 97.704],
                                        [336.194, 97.704],
                                        [336.194, 77.202],
                                        [368.967, 77.202],
                                        [368.967, 66.26],
                                        [322.339, 66.26],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 16",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ind: 16,
                            ty: "sh",
                            ix: 17,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [290.689, 140.193],
                                        [304.496, 140.193],
                                        [304.496, 66.26],
                                        [290.689, 66.26],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 17",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        { ty: "mm", mm: 1, nm: "Merge Paths 1", mn: "ADBE Vector Filter - Merge", hd: false },
                        {
                            ty: "fl",
                            c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: "Fill 1",
                            mn: "ADBE Vector Graphic - Fill",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [507.575, 140.444], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Group 1",
                    np: 19,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 0,
            op: 167,
            st: 0,
            ct: 1,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: "AY",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [79.142, 176.122, 0], ix: 2, l: 2 },
                a: { a: 0, k: [79.116, 182.168, 0], ix: 1, l: 2 },
                s: { a: 0, k: [176.3, 176.3, 100], ix: 6, l: 2 },
            },
            ao: 0,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [6.544, -33.122],
                                        [-31.701, 33.122],
                                        [18.612, 33.122],
                                        [31.701, 10.452],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [79.116, 182.168], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "SHAPE LEFT",
                    np: 1,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [4.276, 36.166],
                                        [20.881, 7.405],
                                        [-4.276, -36.166],
                                        [-20.881, -7.407],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [140.841, 103.393], ix: 2 },
                            a: { a: 0, k: [26.6, -34], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "SHAPE UP",
                    np: 1,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    o: [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                    ],
                                    v: [
                                        [29.694, 25.715],
                                        [0, -25.715],
                                        [-29.694, 25.715],
                                        [-28.663, 25.715],
                                    ],
                                    c: true,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [204.768, 171.491], ix: 2 },
                            a: { a: 0, k: [54.4, -5], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "SHAPE RIGHT",
                    np: 1,
                    cix: 2,
                    bm: 0,
                    ix: 3,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "gf",
                    o: { a: 0, k: 100, ix: 10 },
                    r: 1,
                    bm: 0,
                    g: {
                        p: 3,
                        k: {
                            a: 1,
                            k: [
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 10,
                                    s: [0, 0.996, 0.059, 0.549, 0.5, 0.998, 0.429, 0.38, 1, 1, 0.8, 0.212],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 40,
                                    s: [0.047, 0.404, 0.024, 0.976, 0.524, 0.243, 0.406, 0.982, 1, 0.082, 0.788, 0.988],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 70,
                                    s: [0, 0.404, 0.024, 0.976, 0.5, 0.243, 0.406, 0.982, 1, 0.082, 0.788, 0.988],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 100,
                                    s: [0, 0.925, 0.176, 0.827, 0.5, 0.516, 0.565, 0.79, 1, 0.106, 0.953, 0.753],
                                },
                                {
                                    i: { x: 0.833, y: 0.833 },
                                    o: { x: 0.167, y: 0.167 },
                                    t: 130,
                                    s: [0, 0.925, 0.176, 0.827, 0.5, 0.516, 0.565, 0.79, 1, 0.106, 0.953, 0.753],
                                },
                                { t: 160, s: [0, 0.996, 0.059, 0.549, 0.5, 0.998, 0.429, 0.38, 1, 1, 0.8, 0.212] },
                            ],
                            ix: 9,
                        },
                    },
                    s: { a: 0, k: [19, 41], ix: 5 },
                    e: { a: 0, k: [-27, 92], ix: 6 },
                    t: 1,
                    nm: "Gradient Fill 1",
                    mn: "ADBE Vector Graphic - G-Fill",
                    hd: false,
                },
            ],
            ip: 0,
            op: 167,
            st: 0,
            ct: 1,
            bm: 0,
        },
    ],
    markers: [],
    props: {},
};

export const AnimatedLogo: FC<{ type: "dark" | "light" }> = ({ type }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 3000);
    }, []);

    if (!isLoaded) {
        return type === "dark" ? (
            <DarkIcon className={styles.iconPreview} />
        ) : (
            <WhiteIcon className={styles.iconPreview} />
        );
    }

    return type === "dark" ? (
        <div className={styles.iconAnim}>
            <Lottie animationData={ANIM_DATA_DARK} loop={true} />
        </div>
    ) : (
        <div className={styles.iconAnim}>
            <Lottie animationData={ANIM_DATA_LIGHT} loop={true} />
        </div>
    );
};
